import React from 'react';
import { useNavigate } from 'react-router-dom';

import './FloatingPreview.scss';

const FloatingPreview = props => {
	const { playing } = props;
	const navigate = useNavigate();

	const handleNav = () => navigate(`/podcasts/${playing.id}`);

	return (
		<div className="floating-preview">
			<img
				className="cover-art"
				src={playing.art_src}
				alt="podcast-cover-art"
				onClick={handleNav}
			/>
			<div className="podcast-title">{playing.name}</div>
		</div>
	);
};

export default FloatingPreview;
