/** @format */

import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { reducer as AnalyticsRedux } from './AnalyticsRedux';
import agendaReducer from './reducers/agenda.reducer';
import userReducer from './reducers/user.reducer';
import eventsReducer from './reducers/events.reducer';
import contextReducer from './reducers/context.reducer';
import directoriesReducer from './reducers/directory.reducer';
import leaderboardReducer from './reducers/leaderboard.reducer';
import feedsReducer from './reducers/feeds.reducer';
import announcementsReducer from './reducers/announcements.reducer';
import kudosBadgesReducer from './reducers/kudos.reducer';
import mediaReducer from './reducers/media.reducer';
import notificationPreferencesReducer from './reducers/notificationPreferences.reducer';
import notificationsReducer from './reducers/notifications.reducer';
import unreadReducer from './reducers/unread.reducer';
import widgetsReducer from './reducers/widgets.reducer';
import { reducer as ConnectionRedux } from './ConnectionRedux';
import { reducer as CheckInRedux } from './CheckInRedux';
import { reducer as DomainRedux } from './DomainRedux';
import createSocket from '../createSocket';
import podcastsReducer from './reducers/podcasts.reducer';

const socket = createSocket();

const config = {
	key: 'root',
	storage: storage,
	whitelist: ['user', 'events', 'context'],
	blacklist: [
		'library',
		'announcements',
		'feeds',
		'agenda',
		'directories',
		'connections',
		'checkIns',
		'kudos',
	],
};
export default persistCombineReducers(config, {
	analytics: AnalyticsRedux,
	user: userReducer,
	events: eventsReducer,
	media: mediaReducer,
	announcements: announcementsReducer,
	notifications: notificationsReducer,
	notificationPreferences: notificationPreferencesReducer,
	feeds: feedsReducer,
	agenda: agendaReducer,
	directories: directoriesReducer,
	leaderboard: leaderboardReducer,
	connections: ConnectionRedux,
	checkIns: CheckInRedux,
	kudos: kudosBadgesReducer,
	domains: DomainRedux,
	context: contextReducer,
	unread: unreadReducer,
	widgets: widgetsReducer,
	podcasts: podcastsReducer,
	ws: (state = { socket }) => state,
});
