import React from 'react';
import { Slider } from 'antd';
import { VolumeDown, VolumeUp } from '@blueprintnyc/bpnyc-common-admin/icons';

import './VolumeControls.scss';

const VolumeControls = props => {
	const { volume, setVolume, theme } = props;

	const handleVolumeChange = vol => setVolume(vol);

	return (
		<div className="volume-controls-container" style={{ color: theme.headderText }}>
			<div className="volume-controls">
				<div className="icon">
					<VolumeDown fontSize={11.5} fill={theme.headerText} />
				</div>
				<div className="volume-slider">
					<Slider onChange={handleVolumeChange} value={volume} min={0} max={100} />
				</div>
				<div className="icon">
					<VolumeUp fill={theme.headerText} />
				</div>
			</div>
		</div>
	);
};

export default VolumeControls;
