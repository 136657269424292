// import Api from './ApiController';
import apolloClient from './ApolloClient';
import { handleGraphQLErrors } from './GraphQLErrorHandler';

export default class graphQL {
	static Query = async (query, variables = null) => {
		try {
			const { loading, error, data, networkStatus } = await apolloClient.query({
				query,
				variables,
				fetchPolicy: 'network-only',
			});
			return { loading, error, data, networkStatus };
		} catch (error) {
			return handleGraphQLErrors(error, query, variables);
		}
	};

	static Mutation = async (mutation, variables = null) => {
		try {
			const { loading, error, data, networkStatus } = await apolloClient.mutate({
				mutation,
				variables,
			});
			return { loading, error, data, networkStatus };
		} catch (error) {
			return handleGraphQLErrors(error, mutation, variables);
		}
	};
}
