import React from 'react';
import { connect } from 'react-redux';
import PodcastPlayerRef from './PodcastPlayerRef';

const PodcastPlayerRefContainer = props => {
	const { podcastsRouteViewed } = props;

	return podcastsRouteViewed ? <PodcastPlayerRef /> : null;
};

const msp = ({ podcasts }) => ({
	podcastsRouteViewed: podcasts.podcastsRouteViewed,
});

export default connect(msp, null)(PodcastPlayerRefContainer);
