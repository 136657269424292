import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PodcastActionsMenu from '../PodcastActionsMenu';
import { Play, Pause, Check, Replay } from '@blueprintnyc/bpnyc-common-admin/icons';
import { formatDate, formatSimplifiedTime } from 'common';

import './PodcastDetail.scss';

const circleDivider = <div className="circle-divider" />;

const PodcastDetail = props => {
	const {
		podcast,
		theme,
		pinned,
		playing,
		paused,
		attendeeID,
		updatePlaying,
		showSidePlayer,
	} = props;
	const isVideo = podcast && podcast.mime_type ? podcast.mime_type.includes('video') : false;
	const playedByAttendee = podcast.played_by?.filter(attendee => attendee.id === attendeeID);
	const playIcon = playedByAttendee?.length ? <Replay /> : <Play />;
	const navigate = useNavigate();

	const handleUpdatePlaying = () => updatePlaying(podcast);

	const handleNav = () => (pinned ? navigate(`/podcasts/${podcast.id}`) : null);

	return (
		<div className={`podcast-container ${showSidePlayer ? 'show-side-player' : ''}`}>
			<img
				className="cover-art"
				src={podcast.art_src}
				alt="podcast-cover-art"
				onClick={handleNav}
			/>
			<div className="podcast-info">
				<div>
					<div className="podcast-date-type-duration">
						<div
							className="check-icon"
							style={{
								display: playedByAttendee?.length ? '' : 'none',
							}}
						>
							{playedByAttendee?.length ? <Check fontSize={12} /> : null}
						</div>
						<div className="date">{formatDate(podcast.created_at)}</div>
						{isVideo ? (
							<>
								{circleDivider} <div className="type">VIDEO</div>
							</>
						) : null}
						{circleDivider}
						<div className="duration">
							<div>{formatSimplifiedTime(podcast.duration)}</div>
						</div>
					</div>
					<div className="title">{podcast.name}</div>
					<div className={`description ${pinned ? 'pinned' : ''}`}>{podcast.description}</div>
				</div>
				<div className="podcast-actions">
					<div
						className="play-pause-btn"
						style={{ background: theme.headerBg }}
						onClick={handleUpdatePlaying}
					>
						{playing.id === podcast.id && !paused ? (
							<>
								<Pause /> Pause
							</>
						) : (
							<>{playIcon} Play</>
						)}
					</div>
					<PodcastActionsMenu
						podcast={podcast}
						attendeeID={attendeeID}
						playedByAttendee={playedByAttendee}
					/>
				</div>
			</div>
		</div>
	);
};

const msp = ({ podcasts, user }) => ({
	playing: podcasts.playing.podcast,
	paused: podcasts.playing.paused,
	attendeeID: user.user.id,
	showSidePlayer: podcasts.showSidePlayer,
});

export default connect(msp, null)(PodcastDetail);
