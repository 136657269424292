//TO DO: MAKE THIS REDUCER USE OBJECTS NOT ARRAYS (IN RN TOO)
import {
	SET_KUDOS_LIST,
	SET_BADGES_LIST,
	ADD_KUDO_BADGE,
	UPDATE_KUDO_BADGE,
	DELETE_KUDO_BADGE,
	ADD_GROUPS_TO_KUDO,
	REMOVE_GROUPS_FROM_KUDO,
	ASSIGN_BADGE_TO_ATTENDEE,
	LIKE_AND_DISLIKE_KUDO,
} from 'redux/actions';
import { addGroupsToObject, removeGroupsFromObject } from 'common';

const initialState = {
	kudos: [],
	badges: [],
};

const kudosBadgesReducer = (state = initialState, action) => {
	const {
		type,
		kudos,
		badges,
		kudo,
		kudosOrBadges,
		kudoID,
		groups,
		badge,
		isLiked,
		kudoGiven,
	} = action;
	const stateDup = { ...state };

	switch (type) {
		case SET_KUDOS_LIST:
			return { ...stateDup, kudos: kudos };

		case SET_BADGES_LIST:
			return { ...stateDup, badges: badges };

		case ADD_KUDO_BADGE:
			stateDup[kudosOrBadges] = [...stateDup[kudosOrBadges], kudo];
			return { ...stateDup };

		case UPDATE_KUDO_BADGE:
			stateDup[kudosOrBadges] = stateDup[kudosOrBadges].map(item =>
				item.id === kudo.id ? { ...item, ...kudo } : item
			);
			return { ...stateDup };

		case DELETE_KUDO_BADGE:
			stateDup[kudosOrBadges] = stateDup[kudosOrBadges].filter(item => item.id !== kudoID);
			return { ...stateDup };

		case ADD_GROUPS_TO_KUDO:
			stateDup[kudosOrBadges] = stateDup[kudosOrBadges].map(kudo => {
				if (kudo.id !== kudoID) return kudo;
				else return addGroupsToObject(groups, kudo);
			});
			return { ...stateDup };

		case REMOVE_GROUPS_FROM_KUDO:
			stateDup[kudosOrBadges] = stateDup[kudosOrBadges].map(kudo => {
				if (kudo.id !== kudoID) return kudo;
				else return removeGroupsFromObject(groups, kudo);
			});
			return { ...stateDup };

		case ASSIGN_BADGE_TO_ATTENDEE:
			let updatedBadges = stateDup.badges.filter(b => (b.kudo_id || b.id) !== badge.id);
			if (updatedBadges.length === stateDup.badges.length)
				updatedBadges = [...updatedBadges, badge];
			return { ...stateDup, badges: updatedBadges };

		case LIKE_AND_DISLIKE_KUDO:
			let updatedKudos = stateDup.kudos.map(kudo => {
				if (kudo.id !== kudoID) return kudo;
				else {
					if (!isLiked) kudo.kudo_givens = [...kudo.kudo_givens, kudoGiven];
					else
						kudo.kudo_givens = kudo.kudo_givens.filter(
							kg => kg.attendee_giver_id !== kudoGiven.attendee_giver_id
						);
					return kudo;
				}
			});
			return { ...stateDup, kudos: updatedKudos };
		default:
			return state;
	}
};

export default kudosBadgesReducer;
