import {
	SET_LEADERBOARD,
	SET_LEADERBOARD_ATTENDEE_RECORD,
	SET_LEADERBOARD_REFETCH,
} from 'redux/actions';

const initialState = {
	leaderboard: {},
	attendeeRecord: {},
	leaderboardRefetch: 0,
};

const leaderboardReducer = (state = initialState, action) => {
	const { type, leaderboard, leaderboardAttendeeRecord } = action;
	const stateDup = { ...state };
	switch (type) {
		case SET_LEADERBOARD:
			return { ...stateDup, ...leaderboard };
		case SET_LEADERBOARD_ATTENDEE_RECORD:
			return { ...stateDup, attendeeRecord: { ...leaderboardAttendeeRecord } };
		case SET_LEADERBOARD_REFETCH:
			const leaderboardRefetch = stateDup.leaderboardRefetch + 1;
			return { ...stateDup, leaderboardRefetch };
		default:
			return state;
	}
};

export default leaderboardReducer;
