import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';
import ProgressiveAvatar from 'components/ProgressiveAvatar';

import './Notification.scss';

const Notification = props => {
	const { data } = props;
	const navigate = useNavigate();
	// console.log('Notification');
	// console.log(data);

	const makeTheseWordsBold = (text, words) => {
		let newText = text;
		words.forEach(word => {
			newText = newText.replace(word, `<strong>${word}</strong>`);
		});
		return newText;
	};

	const giver = data.attendee_giver;
	const giverName =
		giver.first_name && giver.last_name ? `${giver.first_name} ${giver.last_name}` : '';
	const parsedLink = data.link && data.link[0] === '{' ? JSON.parse(data.link) : data.link;
	const description = makeTheseWordsBold(data.body, ['post', 'kudo', giverName]);

	const avatar = giver ? <ProgressiveAvatar size={40} attendee={giver} /> : null;

	const isReadClass = data.read ? 'is-read' : '';

	return (
		<div className={`Notification ${isReadClass}`} onClick={() => navigate(parsedLink.route)}>
			<div className="left">
				{avatar}
				<div className="note-info">
					<div className="description">{parse(description)}</div>
					<div className="time">
						<span>{moment(data.created_at).calendar()}</span>
					</div>
				</div>
			</div>
			<div className="right">
				<div className="unread-icon" />
			</div>
		</div>
	);
};

const mapStateToProps = ({}) => {
	return {};
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
