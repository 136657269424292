import {
	SET_LIBRARY,
	SET_SINGLE_MEDIA,
	CLEAR_SINGLE_MEDIA,
	UPDATE_LIBRARY,
	ADD_MEDIA,
	UPDATE_MEDIA,
	REMOVE_MEDIA,
	SET_LIBRARY_VIEW,
	UPDATE_CHILD_LIBRARY,
	ADD_CHILD_LIBRARY,
	DELETE_CHILD_LIBRARY,
	ADD_GROUPS_TO_LIBRARY,
	REMOVE_GROUPS_FROM_LIBRARY,
} from 'redux/actions';
import { arrToObj } from 'common/Helpers';

const initialState = {
	library: {},
	selectedMedia: {},
	libraryView: 'list',
};

const mediaReducer = (state = initialState, action) => {
	const {
		type,
		library,
		libraryID,
		groupIDs,
		groups,
		media,
		mediaID,
		selectedMedia,
		view,
	} = action;
	switch (type) {
		case SET_LIBRARY:
			if (library.media.nodes) library.media = arrToObj(library.media.nodes);
			if (library.libraries) library.libraries = arrToObj(library.libraries);
			return { ...state, library };
		case SET_LIBRARY_VIEW:
			return { ...state, libraryView: view };
		case SET_SINGLE_MEDIA:
			console.log('single:', selectedMedia);
			return { ...state, selectedMedia };

		case CLEAR_SINGLE_MEDIA:
			return { ...state, selectedMedia: {} };

		case UPDATE_LIBRARY:
			return { ...state, library: { ...state.library, ...library } };

		case ADD_MEDIA:
			state.library.media[media.id] = { ...media };
			return { ...state, library: { ...state.library } };

		case UPDATE_MEDIA:
			state.library.media[media.id] = { ...state.library.media[media.id], ...media };
			return { ...state, library: { ...state.library } };

		case REMOVE_MEDIA:
			delete state.library.media[mediaID];
			return { ...state, library: { ...state.library } };

		case ADD_CHILD_LIBRARY:
			state.library.libraries[library.id] = { ...library };
			return { ...state, library: { ...state.library } };

		case UPDATE_CHILD_LIBRARY:
			state.library.libraries[library.id] = { ...state.library.libraries[library.id], ...library };
			return { ...state, library: { ...state.library } };

		case DELETE_CHILD_LIBRARY:
			delete state.library.libraries[libraryID];
			return { ...state, library: { ...state.library } };

		case ADD_GROUPS_TO_LIBRARY:
			const libraryToAddTo = { ...state.library.libraries[libraryID] };
			libraryToAddTo.groups = [...libraryToAddTo.groups, ...groups];
			state.library.libraries[libraryID] = libraryToAddTo;
			return { ...state, library: { ...state.library } };

		case REMOVE_GROUPS_FROM_LIBRARY:
			const libraryToRemoveFrom = { ...state.library.libraries[libraryID] };
			libraryToRemoveFrom.groups = libraryToRemoveFrom.groups.filter(
				group => !groupIDs.includes(group.id)
			);
			state.library.libraries[libraryID] = libraryToRemoveFrom;
			return { ...state, library: { ...state.library } };

		default:
			return state;
	}
};

export default mediaReducer;
