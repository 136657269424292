import React from 'react';
import { connect } from 'react-redux';
import { PodcastMediaPreview } from 'components/Podcasts';
import { Sort } from '@blueprintnyc/bpnyc-common-admin/icons';

import './PodcastsList.scss';

const PodcastsList = props => {
	const { podcasts, theme, updatePlaying, sortOrder, setSortOrder, sidePlayerView } = props;

	const handleUpdateSortOrder = () => setSortOrder(sortOrder === 'dec' ? 'asc' : 'dec');

	return (
		<div className="podcast-list-container">
			<div
				className="header"
				style={{ justifyContent: sidePlayerView ? 'flex-start' : 'space-between' }}
			>
				<div className="section-title">Episodes</div>
				<div className="sort-container" onClick={handleUpdateSortOrder}>
					<Sort fontSize={16} style={{ transform: sortOrder === 'asc' ? 'rotateY(180deg)' : '' }} />
					{sortOrder === 'dec' ? 'Newest to Oldest' : 'Oldest to Newest'}
				</div>
			</div>
			<div className="podcast-list">
				{podcasts && podcasts.length
					? podcasts.map((podcast, i) => (
							<PodcastMediaPreview
								key={i}
								podcast={podcast}
								theme={theme}
								updatePlaying={updatePlaying}
							/>
					  ))
					: 'No podcasts available for this event.'}
			</div>
		</div>
	);
};

const msp = ({ podcasts }) => ({
	sidePlayerView: podcasts.sidePlayerView,
});

export default connect(msp, null)(PodcastsList);
