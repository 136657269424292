import React from 'react';
import SubscriptionTemplate from './SubscriptionTemplate';
import { connect } from 'react-redux';
import { addNewPodcast, updatePodcast, deletePodcast } from 'redux/actions';

const WS_PODCASTS_BY_HTTP = {
	ADD_PODCAST: 'podcasts/POST',
	UPDATE_PODCAST: 'podcasts/:podcastID/PATCH',
	DELETE_PODCAST: 'podcasts/:podcastID/DELETE',
};

const PodcastSubscriptions = props => {
	const { socket, eventID, addNewPodcast, updatePodcast, deletePodcast } = props;
	const propsToSend = { ...props };
	delete propsToSend.children;

	const subscribe = () => {
		console.log('⚡️ PodcastsSubscriptions Mounted ⚡️');
		socket.emit('app/event/:eventID/podcasts/SUBSCRIBE', { eventID });
	};

	const unsubscribe = () => {
		console.log(`🚶‍♂️ PodcastsSubscriptions Unmounted 🚶‍♂️`);
		socket.emit('app/event/:eventID/podcasts/UNSUBSCRIBE', { eventID });
	};

	// podcast added
	const listenforNewPodcast = () => {
		socket.on(WS_PODCASTS_BY_HTTP.ADD_PODCAST, payload => {
			const { data, error } = payload;
			if (error) console.log('Error adding podcast', error);
			else {
				addNewPodcast(data);
			}
		});
	};

	// podcast updated
	const listenforUpdatedPodcast = () => {
		socket.on(WS_PODCASTS_BY_HTTP.UPDATE_PODCAST, payload => {
			const { data, error } = payload;
			if (error) console.log('Error updating podcast', error);
			else {
				updatePodcast(data);
			}
		});
	};

	// podcast deleted
	const listenforDeletedPodcast = () => {
		socket.on(WS_PODCASTS_BY_HTTP.DELETE_PODCAST, payload => {
			const { data, error } = payload;
			if (error) console.log('Error deleting podcast', error);
			else {
				deletePodcast(data);
			}
		});
	};

	const listeners = () => [listenforNewPodcast, listenforUpdatedPodcast, listenforDeletedPodcast];

	return (
		<>
			<SubscriptionTemplate
				{...props}
				sub={subscribe}
				unsub={unsubscribe}
				listeners={listeners()}
				routes={Object.values(WS_PODCASTS_BY_HTTP)}
				from={'podcasts'}
			/>
			{React.cloneElement(props.children, propsToSend)}
		</>
	);
};

const msp = ({ ws }) => ({
	socket: ws.socket,
});

const mdp = dispatch => ({
	updatePodcast: podcast => dispatch(updatePodcast(podcast)),
	addNewPodcast: podcast => dispatch(addNewPodcast(podcast)),
	deletePodcast: podcastID => dispatch(deletePodcast(podcastID)),
});

export default connect(msp, mdp)(PodcastSubscriptions);
