import GraphQLController from './GraphQL';
import WidgetsGQL from './gql/WidgetsGQL';

const WidgetServices = {
	widgets: async event_id => {
		let query = WidgetsGQL.Widgets;
		let result = await GraphQLController.Query(query, { event_id });
		return result;
	},
	widget: async id => {
		let query = WidgetsGQL.Widget;
		let result = await GraphQLController.Query(query, { id });
		return result;
	},
};

export default WidgetServices;
