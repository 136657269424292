import React, { Fragment, lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	WebAppSubscriptions,
	AttendeeSubscriptions,
	GroupSubscriptions,
} from 'components/WebsocketSubscriptions';
import WithAuth from 'components/AuthWrapper/WithAuth';
import BrandingProvider from 'components/BrandingProvider';
import Layouts from './Layouts';
import { reloadPageIfChunkNotFound } from '../common/Helpers';
import PodcastPlayerRef from './PodcastPlayerRef';

const Login = lazy(() => import('./Login').catch(reloadPageIfChunkNotFound));
const Logout = lazy(() => import('./Logout').catch(reloadPageIfChunkNotFound));
const SSOLoginCallback = lazy(() => import('./SSOLoginCallback').catch(reloadPageIfChunkNotFound));
const PasswordReset = lazy(() => import('./PasswordReset').catch(reloadPageIfChunkNotFound));
const Dashboard = lazy(() => import('./Dashboard').catch(reloadPageIfChunkNotFound));
const SocialFeeds = lazy(() => import('./SocialFeeds').catch(reloadPageIfChunkNotFound));
const SocialFeedPost = lazy(() => import('./SocialFeedPost').catch(reloadPageIfChunkNotFound));
const EventDispatcher = lazy(() => import('./EventDispatcher').catch(reloadPageIfChunkNotFound));
const EventSelection = lazy(() => import('./Event').catch(reloadPageIfChunkNotFound));
const Directory = lazy(() => import('./Directory').catch(reloadPageIfChunkNotFound));
const Profile = lazy(() => import('./Profile').catch(reloadPageIfChunkNotFound));
const Agenda = lazy(() => import('./Agenda').catch(reloadPageIfChunkNotFound));

const Connections = lazy(() => import('./Connections').catch(reloadPageIfChunkNotFound));
const Checkins = lazy(() => import('./Checkins').catch(reloadPageIfChunkNotFound));
const ScanQR = lazy(() => import('./ScanQR').catch(reloadPageIfChunkNotFound));
const Announcements = lazy(() => import('./Announcements').catch(reloadPageIfChunkNotFound));
const Notifications = lazy(() => import('./Notifications').catch(reloadPageIfChunkNotFound));
const PageStack = lazy(() => import('./PageStack').catch(reloadPageIfChunkNotFound));
const LibraryStack = lazy(() => import('./LibraryStack').catch(reloadPageIfChunkNotFound));
const MediaStack = lazy(() => import('./MediaStack').catch(reloadPageIfChunkNotFound));
const MediaStackSingle = lazy(() => import('./MediaStackSingle').catch(reloadPageIfChunkNotFound));
const ExternalFrame = lazy(() => import('./ExternalFrame').catch(reloadPageIfChunkNotFound));
const Broadcast = lazy(() => import('./Broadcast').catch(reloadPageIfChunkNotFound));
const Photobooth = lazy(() => import('./Photobooth').catch(reloadPageIfChunkNotFound));
const AIPhotobooth = lazy(() => import('./AIPhotobooth').catch(reloadPageIfChunkNotFound));
const Leaderboard = lazy(() => import('./Leaderboard').catch(reloadPageIfChunkNotFound));
const Settings = lazy(() => import('./Settings').catch(reloadPageIfChunkNotFound));
const PodcastsWrapper = lazy(() => import('./PodcastsWrapper').catch(reloadPageIfChunkNotFound));

const App = ({ user }) => {
	const { groups, id: attendeeID } = user;
	return (
		<Fragment>
			<Suspense
				fallback={
					attendeeID ? (
						<Layouts>
							<div
								style={{
									width: '100%',
									height: '100vh',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<Spin size="large" />
							</div>
						</Layouts>
					) : null
				}
			>
				<WebAppSubscriptions>
					<AttendeeSubscriptions attendeeID={attendeeID}>
						<GroupSubscriptions userGroups={groups}>
							<Routes>
								<Route path="/login" element={<Login />} />
								<Route path="/sso" element={<SSOLoginCallback />} />
								<Route path="/passwordreset" element={<PasswordReset />} />
								<Route path="/ExternalFrame/" element={<ExternalFrame />} />
								<Route path="/logout" element={<Logout />} />
								<Route
									path="/dashboard"
									element={
										<WithAuth>
											<Dashboard />
										</WithAuth>
									}
								/>
								<Route
									path="/pageStack/:page"
									element={
										<WithAuth>
											<PageStack />
										</WithAuth>
									}
								/>
								<Route
									path="/agenda"
									element={
										<WithAuth>
											<Agenda />
										</WithAuth>
									}
								/>
								<Route
									path="/connections"
									element={
										<WithAuth>
											<Connections />
										</WithAuth>
									}
								/>
								<Route
									path="/checkIn"
									element={
										<WithAuth>
											<Checkins />
										</WithAuth>
									}
								/>
								<Route
									path="/scanQR"
									element={
										<WithAuth>
											<ScanQR />
										</WithAuth>
									}
								/>
								<Route
									path="/announcements"
									element={
										<WithAuth>
											<Announcements />
										</WithAuth>
									}
								/>
								<Route
									path="/notifications"
									element={
										<WithAuth>
											<Notifications />
										</WithAuth>
									}
								/>
								<Route
									path="/post/:id"
									element={
										<WithAuth>
											<SocialFeedPost />
										</WithAuth>
									}
								/>
								<Route
									path="/feed"
									element={
										<WithAuth>
											<SocialFeeds />
										</WithAuth>
									}
								/>
								<Route
									path="/mediaStack/:mediaId"
									element={
										<WithAuth>
											<MediaStack />
										</WithAuth>
									}
								/>
								<Route
									path="/mediaStackSingle/:page"
									element={
										<WithAuth>
											<MediaStackSingle />
										</WithAuth>
									}
								/>
								<Route
									path="/broadcasts/:sessionId"
									element={
										<WithAuth>
											<Broadcast />
										</WithAuth>
									}
								/>
								<Route
									path="/photobooth/:camId"
									element={
										<WithAuth>
											<Photobooth />
										</WithAuth>
									}
								/>
								<Route
									path="/ai-photobooth/:pbId"
									element={
										<WithAuth>
											<AIPhotobooth />
										</WithAuth>
									}
								/>
								<Route
									path="/libraryStack/:page"
									element={
										<WithAuth>
											<LibraryStack />
										</WithAuth>
									}
								/>
								<Route
									path="/materials"
									element={
										<WithAuth>
											<LibraryStack isMaterialsRoute={true} />
										</WithAuth>
									}
								/>
								<Route
									path="/podcasts"
									element={
										<WithAuth>
											<PodcastsWrapper />
										</WithAuth>
									}
								/>
								<Route
									path="/podcasts/:id"
									element={
										<WithAuth>
											<PodcastsWrapper />
										</WithAuth>
									}
								/>
								<Route
									path="/leaderboard/:id"
									element={
										<WithAuth>
											<Leaderboard />
										</WithAuth>
									}
								/>
								<Route
									path="/standalone-leaderboard/:eventId/:id"
									element={<Leaderboard standalone={true} />}
								/>
								<Route
									path="/directory"
									element={
										<WithAuth>
											<Directory />
										</WithAuth>
									}
								/>
								<Route
									path="/settings"
									element={
										<WithAuth>
											<Settings />
										</WithAuth>
									}
								/>
								<Route
									path="/directory/profile/:username"
									element={
										<WithAuth>
											<Profile />
										</WithAuth>
									}
								/>
								<Route path="/attendeeDetail" element={<Profile />} />
								<Route
									path="/events"
									element={
										<WithAuth>
											<EventSelection />
										</WithAuth>
									}
								/>
								<Route
									path="/"
									element={
										<WithAuth>
											<EventDispatcher />
										</WithAuth>
									}
								/>
							</Routes>
						</GroupSubscriptions>
					</AttendeeSubscriptions>
				</WebAppSubscriptions>
				<PodcastPlayerRef />
				<BrandingProvider />
			</Suspense>
		</Fragment>
	);
};

const msp = ({ user }) => ({
	user: user.user,
});

export default connect(msp, null)(App);
