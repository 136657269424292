import React from 'react';
import { connect } from 'react-redux';
import {
	updateAutoplay,
	updatePausedStatus,
	clearPlayingPodcast,
	updateSidePlayerView,
} from 'redux/actions';
import { Close } from '@blueprintnyc/bpnyc-common-admin/icons';
import { NowPlaying, UpNext } from 'components/Podcasts';

import './PodcastPlayer.scss';

const PodcastPlayer = props => {
	const {
		playing,
		theme,
		updatePlaying,
		view,
		setView,
		playNextPodcast,
		volume,
		setVolume,
		autoplay,
		playLocation,
		toggleAutoplay,
		togglePausedStatus,
		paused,
		upNextPlaylist,
		setShowSidePlayer,
		handleSeekerChange,
		nextPodcast,
		updateSidePlayerView,
	} = props;

	const handleCloseSidebar = () => {
		updateSidePlayerView(false);
		setShowSidePlayer(false);
	};

	return (
		<div className="podcast-player-container" style={{ background: theme.headerBg }}>
			<div
				className="close-btn"
				onClick={handleCloseSidebar}
				style={{ background: theme.brandPrimary }}
			>
				<Close fontSize={10} color={theme.headerText} />
			</div>
			<NowPlaying
				playing={playing}
				theme={theme}
				setView={setView}
				playNextPodcast={playNextPodcast}
				volume={volume}
				setVolume={setVolume}
				playLocation={playLocation}
				handleSeekerChange={handleSeekerChange}
				toggleAutoplay={toggleAutoplay}
				togglePausedStatus={togglePausedStatus}
				paused={paused}
				upNextPlaylist={upNextPlaylist}
				autoplay={autoplay}
				nextPodcast={nextPodcast}
			/>
			{playing && view === 'up-next' ? (
				<UpNext
					playing={playing}
					podcasts={upNextPlaylist}
					setView={setView}
					theme={theme}
					updatePlaying={updatePlaying}
				/>
			) : null}
		</div>
	);
};

const msp = ({ podcasts }) => ({
	paused: podcasts.playing.paused,
	upNextPlaylist: podcasts.upNextPlaylist,
	autoplay: podcasts.autoplay,
});

const mdp = dispatch => ({
	toggleAutoplay: async autoplayStatus => dispatch(updateAutoplay(autoplayStatus)),
	togglePausedStatus: async pausedStatus => dispatch(updatePausedStatus(pausedStatus)),
	clearPlayingPodcast: async () => dispatch(clearPlayingPodcast()),
	updateSidePlayerView: async view => dispatch(updateSidePlayerView(view)),
});

export default connect(msp, mdp)(PodcastPlayer);
