import {
	SET_EVENT,
	SET_EVENTS,
	CLEAR_EVENT,
	CREATE_OR_DUPLICATE_GROUP,
	UPDATE_GROUP,
	DELETE_GROUP,
	CREATE_PAGE,
	UPDATE_PAGE,
	DELETE_PAGE,
	SET_PUBLIC_EVENT_THEME,
} from 'redux/actions';

const initialState = {
	events: [],
	event: [],
	publicEventTheme: {},
};

const eventsReducer = (state = initialState, action) => {
	Object.freeze(state);
	const stateDup = { ...state };
	const { type, event, events, group, groupID, page, pageID, theme } = action;

	switch (type) {
		case SET_EVENTS:
			return { ...state, events };
		case SET_EVENT:
			return { ...state, event: { ...stateDup.event, ...event } };
		case CLEAR_EVENT:
			return { ...state, event: [] };
		case CREATE_OR_DUPLICATE_GROUP:
			stateDup.event = { ...stateDup.event, groups: [...stateDup.event.groups, group] };
			return stateDup;
		case UPDATE_GROUP:
			stateDup.event.groups = stateDup.event.groups.map(g =>
				g.id === group.id ? { ...g, ...group } : g
			);
			return stateDup;
		case DELETE_GROUP:
			stateDup.event.groups = stateDup.event.groups.filter(g => g.id !== groupID);
			return stateDup;
		case CREATE_PAGE:
			stateDup.event = { ...stateDup.event, pages: [...stateDup.event.pages, page] };
			return stateDup;
		case UPDATE_PAGE:
			//use a different way
			const newState = JSON.parse(JSON.stringify(stateDup));
			newState.event.pages = newState.event.pages.map(p =>
				p.id === page.id ? { ...p, ...page } : p
			);
			return newState;
		case DELETE_PAGE:
			stateDup.event.pages = stateDup.event.pages.filter(p => p.id !== pageID);
			return stateDup;
		case SET_PUBLIC_EVENT_THEME:
			return { ...state, publicEventTheme: theme };
		default:
			return state;
	}
};

export default eventsReducer;
