import React from 'react';
import moment from 'moment';
import tlds from 'tlds';
import { ArrowLeftOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { AuditServices } from '../services';

const validURLCheck = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

const isJson = data => {
	if (!data) data = {};
	else if (typeof data === 'string') data = JSON.parse(data);
	return data;
};

const checkMeta = (text, metadata) => {
	if (typeof text !== 'string') {
		return;
	}
	metadata = isJson(metadata);
	const arr = Array.from(Object.keys(metadata), k => [`${k}`, metadata[k]]);
	var i = 0;
	if (typeof text !== 'undefined') {
		while (arr[i]) {
			var include = '{{' + arr[i][0] + '}}';
			if (text.includes(include)) {
				text = text.replace(include, arr[i][1]);
			}
			i++;
		}
	}
	return text;
};
const checkForEmptyMetadata = (value, metadata) => {
	//regex removes html tags
	const regex = /(<([^>]+)>)/gi;
	const metadataArr = value.match(/\{{(.*?)\}}/gi);
	if (!metadataArr) return true;
	const result = metadataArr.some(value => {
		const valueTxt = value.replace(/\{{|\}}/gi, '');
		return metadata[valueTxt];
	});
	if (!result) return false;
	const valueWithNoTags = value.replace(regex, '');
	return Boolean(checkMeta(valueWithNoTags, metadata));
};
const audit = auditRecord => {
	AuditServices.AddAuditRecord({ audit: auditRecord });
};

const auditClick = (name, payload) => {
	audit({ name, payload, action: 'click' });
};

const auditView = (name, payload) => {
	audit({ name, payload, action: 'view' });
};

const isMobile = () => {
	return window.innerWidth <= 760;
};

const IconsCheck = icon => {
	if (typeof icon !== 'string') return;
	icon = icon
		.replace('Io', '')
		.split(/(?=[A-Z])/)
		.map(w => w.toLowerCase())
		.join('-');
	icon =
		icon.search('ios-') && icon.search('md-')
			? 'ios-' + (icon === 'profile' ? 'clipboard' : icon === 'picture' ? 'link' : icon)
			: icon;
	return icon;
};

const buildAuthHistory = loginMethod => {
	const userAgent = window.navigator.userAgent;
	let browser;
	let version;
	if (userAgent.indexOf('Chrome') > -1) {
		browser = 'Chrome';
		const versionFull = userAgent.substring(userAgent.indexOf('Chrome') + 7);
		version = versionFull.substring(0, versionFull.indexOf(' '));
	} else if (userAgent.indexOf('MSIE') > -1) {
		browser = 'Internet Explorer';
		version = userAgent.substring(userAgent.indexOf('MSIE') + 5);
	} else if (userAgent.indexOf('Firefox') > -1) {
		browser = 'Firefox';
		version = userAgent.substring(userAgent.indexOf('Firefox') + 8);
	} else if (userAgent.indexOf('Safari') > -1) {
		browser = 'Safari';
		version = userAgent.substring(userAgent.indexOf('Safari') + 8);
	}

	const authHistoryWeb = {
		device_uniq_id: 'web',
		device_manufacturer: loginMethod,
		device_model: window.navigator.appName,
		device_id: browser,
		device_os: window.navigator.platform,
		device_os_version: version,
		app_bundle_id: 'web',
		app_version: 'web',
		app_build_version: 'web',
	};
	return authHistoryWeb;
};

const hexToRGB = (h, opacity) => {
	try {
		let r = 0,
			g = 0,
			b = 0,
			a = opacity ? opacity : 1;

		if (h && h.length === 4) {
			r = '0x' + h[1] + h[1];
			g = '0x' + h[2] + h[2];
			b = '0x' + h[3] + h[3];
		} else if (h && h.length === 7) {
			r = '0x' + h[1] + h[2];
			g = '0x' + h[3] + h[4];
			b = '0x' + h[5] + h[6];
		}

		return 'rgba(' + +r + ',' + +g + ',' + +b + ',' + +a + ')';
	} catch (error) {
		console.error(error);
	}
};

const isHttpUrl = string => string.includes('http://') || string.includes('https://');

const isUrl = string => {
	if (typeof string !== 'string') {
		return false;
	}
	const hasADot = regex['url'].test(string);
	if (!hasADot) {
		return false;
	}
	const hasHttp = isHttpUrl(string);
	if (hasHttp) {
		return true;
	}
	// this is a best efforts "good enough" check that the url has a tld after the final period
	// does not account for urls with any periods in their url params
	// but hopefully if someone is sharing those, they have http:// or https:// and pass the above isValidUrl check
	const afterLastDot = string.substring(string.lastIndexOf('.') + 1);
	const firstNonAlphaCharIdx = afterLastDot.search(/[^A-Za-z0-9]/);
	const firstWordAfterLastDot =
		firstNonAlphaCharIdx === -1 ? afterLastDot : afterLastDot.substring(0, firstNonAlphaCharIdx);
	return tlds.includes(firstWordAfterLastDot);
};

const stripUrlParamsFromLink = url => {
	const idx = url.indexOf('?');
	return idx <= 0 ? url : url.substring(0, idx);
};

const getUrlVars = url => {
	var vars = {};
	url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(key, value) {
		vars[key] = value;
	});
	return vars;
};

const getPassedEvents = (event, isWidget) => {
	let passedEvent = false;
	let eventClassName = 'agendaEvent';

	const eventStartDay = moment(event.start_time);
	const todaysDate = moment();
	const eventEndTime = moment(event.end_time).unix();
	const widgetEndTime = moment(event.end_time)
		.add(10, 'minutes')
		.unix();
	const currentTime = moment().unix();

	if (todaysDate.isAfter(eventStartDay, 'day')) {
		passedEvent = true;
		eventClassName = 'passedAgendaEvent';
	} else if (eventStartDay.format('LL') === todaysDate.format('LL') && eventEndTime < currentTime) {
		eventClassName = 'passedAgendaEvent';
		passedEvent = true;

		if (isWidget) {
			if (widgetEndTime > currentTime) {
				eventClassName = 'passedAgendaEvent';
				passedEvent = false;
			} else {
				eventClassName = 'passedDisplayEvent';
				passedEvent = true;
			}
		}
	}

	return [passedEvent, eventClassName];
};

const backButton = (navigate, view, color) => {
	const style = {
		color: color ? color : 'white',
		fontSize: '2vh',
		margin: view === 'web' ? '0 0 0 10px' : 'auto',
	};
	return view === 'media' || view === 'library' ? (
		<CloseOutlined onClick={() => navigate(-1)} style={style} />
	) : (
		<ArrowLeftOutlined onClick={() => navigate(-1)} style={style} />
	);
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const regex = {
	// hashtag regex matches #hashtag #words
	hashtag: /(^|\s)(#[\w\-_]+)/g,
	// socialTag regex matches @4720 and @28219
	socialTag: /(@[1-9][0-9]*)/g,
	// url regex matches a wide variation of urls
	// including any time a period is sandwiched with alpha-numeric characters: asdfae3.cjakse2
	url: /([\w\-/:]+\.[\w\-.]+[\w\-/=?%&#]+)/g,
};

const getTagForAttendee = attendee => {
	return `@${attendee.first_name} ${attendee.last_name}`;
};

const getParam = param => {
	let paramsString = window.location.search ? window.location.search : window.location.hash;
	paramsString = paramsString.substring(paramsString.indexOf('?token'));
	const urlParams = new URLSearchParams(paramsString);
	return urlParams.get(param);
};

const getMetadataURL = (url, metadata) => {
	return url.includes('{{') ? checkMeta(url, metadata) : url;
};

const buildUrlWithQueryParams = (link, metadata, queryParams) => {
	const linkWithMetadata = link.includes('{{') ? checkMeta(link, metadata) : link;
	if (queryParams) {
		var urlLink = new URL(linkWithMetadata);
		Object.keys(queryParams).forEach(key => urlLink.searchParams.set(key, queryParams[key]));
		return urlLink.toString();
	} else {
		return linkWithMetadata;
	}
};

const tryParseJSONObject = jsonString => {
	try {
		return JSON.parse(jsonString);
	} catch (e) {
		return jsonString;
	}
};

const validateLinkFormat = link => {
	if (!link) return {};
	const isStr = typeof link === 'string';
	const isRouteStr = isStr && link.startsWith('/');
	const isLinkStr = isStr && validURLCheck.test(link);
	if (isRouteStr) return { route: link };
	if (isLinkStr) return { link: link };
	return link;
};

const getCorrectUrl = (item, queryParams, body, className, key, metadata) => {
	let linkObj = tryParseJSONObject(item.link || item.route);
	linkObj = validateLinkFormat(linkObj);

	if (item.eventID) {
		return (
			<Link to={'/'} state={{ eventID: item.eventID }} key={key}>
				{body}
			</Link>
		);
	}

	let itemURL = linkObj.link
		? linkObj.link
		: linkObj.route
		? linkObj.route
		: item.src
		? item.src
		: item.path
		? item.path
		: null;

	if (typeof itemURL === 'string' && itemURL.includes('{{')) {
		itemURL = getMetadataURL(itemURL, metadata);
	}

	if (validURLCheck.test(itemURL)) {
		if (!item.open_in_browser && !item.openInBrowser && !linkObj.openInBrowser) {
			return (
				<Link to={`/ExternalFrame/?link=${itemURL}`} className={className} key={key}>
					{body}
				</Link>
			);
		} else {
			itemURL = buildUrlWithQueryParams(itemURL, null, queryParams);
			const href = itemURL.includes('http') ? itemURL : `//${itemURL}`;
			return (
				<a href={href} target="_blank" rel="noopener noreferrer" className={className} key={key}>
					{body}
				</a>
			);
		}
	} else {
		if (itemURL === null) {
			return <span>{body}</span>;
		}
		if (itemURL === '/home') itemURL = '/dashboard';
		return (
			<Link to={{ pathname: itemURL }} className={className} key={key}>
				{body}
			</Link>
		);
	}
};

const capitalize = str => {
	if (str && str.length > 0) {
		return str[0].toUpperCase() + str.slice(1);
	}
	return str;
};

const isPng = type => {
	return type && (type.includes('.png') || type.includes('.PNG') || type.includes('/png'));
};
const isJpg = type => {
	return (
		type &&
		(type.includes('.jpg') ||
			type.includes('.JPG') ||
			type.includes('.jpeg') ||
			type.includes('.JPEG') ||
			type.includes('/jpeg'))
	);
};
const prefixFilename = (original, type, prefix) => {
	if (isPng(type) || isJpg(type)) {
		const chunks = original.split('/');
		chunks[chunks.length - 1] = prefix + chunks[chunks.length - 1];
		return chunks.join('/');
	} else {
		return original;
	}
};

const getSourcesForJpg = (original, type) => {
	const small = prefixFilename(original, type, '500Kb-');
	const thumbnail = prefixFilename(original, type, '50Kb-');
	return { thumbnail, small, original };
};

const getSourcesForPng = (original, type) => {
	const small = prefixFilename(original, type, 'thumb-');
	return { small, original };
};

const getSourcesForImage = (original, mimeType) => {
	const type = mimeType || original;
	if (!original) {
		return [];
	} else if (isPng(type)) {
		return getSourcesForPng(original, type);
	} else if (isJpg(type)) {
		return getSourcesForJpg(original, type);
	} else {
		return [original];
	}
};

const arrToObj = (data, formatter, key = 'id') =>
	Array.isArray(data)
		? data.reduce((acc, datum) => {
				formatter ? (acc[datum[key]] = formatter(datum)) : (acc[datum[key]] = datum);
				return acc;
		  }, {})
		: data;

const sortByTime = field => (a, b) => new Date(b[field]) - new Date(a[field]);

const removeGroupsFromObject = (groupIDs, item) => {
	const groupsToRemove = groupIDs.map(group => group.id);
	const newItem = { ...item };
	newItem.groups = newItem.groups.filter(group => !groupsToRemove.includes(group.id));
	return newItem;
};

const addGroupsToObject = (groupIDs, item) => {
	const newItem = { ...item };
	newItem.groups = [...item.groups, ...groupIDs];
	return newItem;
};

const getUrlFromHtmlScript = htmlString => {
	try {
		return htmlString.split('src=')[1].split(/'|"/)[1];
	} catch (error) {
		return null;
	}
};

const hasLink = link => {
	const parsedLink = link && tryParseJSONObject(link);
	return parsedLink && (parsedLink.link || parsedLink.route);
};

const reloadPageIfChunkNotFound = error => {
	const hasReloaded = sessionStorage.getItem('hasReloaded');
	if (!hasReloaded) {
		sessionStorage.setItem('hasReloaded', 'true');
		window.location.reload();
	} else {
		console.error('Chunk loading failed after reload:', error);
		setTimeout(() => {
			sessionStorage.removeItem('hasReloaded');
		}, 60000); // Clear after 1 minute
	}
};

export {
	audit,
	auditClick,
	auditView,
	isMobile,
	IconsCheck,
	isJson,
	isUrl,
	checkMeta,
	buildAuthHistory,
	hexToRGB,
	stripUrlParamsFromLink,
	getUrlVars,
	getPassedEvents,
	backButton,
	isSafari,
	regex,
	getTagForAttendee,
	getParam,
	checkForEmptyMetadata,
	getCorrectUrl,
	getMetadataURL,
	capitalize,
	validURLCheck,
	buildUrlWithQueryParams,
	getSourcesForImage,
	arrToObj,
	sortByTime,
	removeGroupsFromObject,
	addGroupsToObject,
	getUrlFromHtmlScript,
	hasLink,
	tryParseJSONObject,
	reloadPageIfChunkNotFound,
};

export const formatDate = date => moment(date).format('ll');

export const formatTime = time => {
	const s = Math.floor(time % 60);
	const formattedS = s < 10 ? `0${s}` : s;
	const hr = Math.floor(time / 3600);
	const min = Math.floor((time % 3600) / 60);

	return hr ? `${hr}:${min}:${formattedS}` : `${min}:${formattedS}`;
};

export const formatSimplifiedTime = time => {
	const s = Math.ceil(time % 60);
	const hr = Math.floor(time / 3600);
	const min = Math.floor((time % 3600) / 60);

	return !min ? `${s}s` : `${hr ? hr + 'h ' : ''}${min}m`;
};

export const formatDescription = description => {
	if (description.length > 250) {
		return description.slice(0, 250) + '...';
	}
	return description;
};

export const shortenTitle = title => {
	if (title.length > 40) {
		return title.slice(0, 40) + '...';
	}
	return title;
};
