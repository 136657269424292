import * as Sentry from '@sentry/react';

/*
 * We explicitly handle unauthorized and network errors. If the error is not one of these,
 * we capture it with Sentry.
 */
export const handleGraphQLErrors = (error, operation, variables) => {
	console.log('GraphQL error:', error?.graphQLErrors, 'networkError', error?.networkError);

	if (error?.networkError?.statusCode === 401) {
		console.log('401 error - logging out in two seconds');
		console.log(
			'This 401 is likely either because we rotated the JWT_SECRET or your jwt token has expired.'
		);
		setTimeout(() => {
			if (!window.location.href.includes('/logout') && !window.location.href.includes('/login')) {
				console.log('401 -> redirecting to /logout');
				window.location.href = `${window.location.origin}/logout`;
			}
		}, 2000);
		return { error: { message: 'Unauthorized access. Logging out.' } };
	} else if (
		error?.networkError?.message &&
		error.networkError.message.includes('Network request failed')
	) {
		return { error: { message: 'Network request failed. Please check your internet connection.' } };
	} else if (error?.graphQLErrors?.length) {
		let errorMessage;
		if (typeof error.graphQLErrors === 'string') {
			try {
				errorMessage = JSON.parse(error.graphQLErrors);
			} catch (e) {
				errorMessage = error.graphQLErrors;
			}
		} else {
			errorMessage = error.graphQLErrors[0].message;
		}

		Sentry.captureMessage('GraphQL error', {
			extra: {
				error,
				operation: operation?.loc ? operation.loc.source.body : '',
				variables,
			},
		});
		return { error: { message: errorMessage } };
	} else {
		Sentry.captureMessage('Unknown GraphQL error', {
			extra: {
				error,
				operation: operation?.loc ? operation.loc.source.body : '',
				variables,
			},
		});
		return { error: { message: 'An unexpected error occurred.' } };
	}
};
