import GraphQLController from './GraphQL';
import FeedsGQL from './gql/FeedsGQL';

const FeedsServices = {
	PostFeed: async query => {
		let variables = { post: query.post };
		let mutation = FeedsGQL.PostFeedGQL;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
	UpdateFeed: async query => {
		let variables = { post: query.post };
		let mutation = FeedsGQL.UpdateFeedGQL;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
	DeleteFeed: async id => {
		let variables = { id };
		let mutation = FeedsGQL.DeleteFeedGQL;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
	GetFeeds: async (event_id, offset, items_per_page, search_query, filter) => {
		let variables = {
			event_id,
			offset,
			items_per_page,
			search_query,
			filter,
		};
		let result = await GraphQLController.Query(FeedsGQL.GetFeedsGQL, variables);
		return result;
	},
	PostComment: async query => {
		let variables = { post: query.post };
		let mutation = FeedsGQL.PostCommentGQL;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
	GetPost: async id => {
		let variables = { id };
		let mutation = FeedsGQL.GetPostGQL;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	PostFeedLike: async params => {
		const { post_id, attendee_id, increment } = params;
		let variables = {
			post_id,
			attendee_id,
			increment,
		};
		let mutation = FeedsGQL.PostFeedLikeGQL;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
	GetFeedLikes: async id => {
		let variables = { id };
		let mutation = FeedsGQL.GetFeedLikesGQL;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	DeleteComment: async commentID => {
		let variables = { id: commentID };
		let mutation = FeedsGQL.DeleteComment;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
	getPinnedPosts: async event_id => {
		let query = FeedsGQL.getPinnedPosts;
		let result = await GraphQLController.Query(query, { event_id });
		return result;
	},
};

export default FeedsServices;
