import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'common';
import { Info } from '@blueprintnyc/bpnyc-common-admin/icons';

import './PlayerPreview.scss';

const PlayerPreview = props => {
	const { playing, theme } = props;
	const isVideo = playing.mime_type?.includes('video');
	const navigate = useNavigate();

	const navigateToPodcast = () => navigate(`/podcasts/${playing.id}`);

	return (
		<div className="player-preview-container" style={{ color: theme.headerText }}>
			<div className="cover-art-container">
				{!isVideo ? (
					<img className="cover-art" src={playing.art_src} alt="podcast-cover-art" />
				) : null}
			</div>
			<div className="title-actions">
				<div className="player-preview-title">{playing?.name || ''}</div>
				<div className="actions" onClick={navigateToPodcast}>
					<Info />
				</div>
			</div>
			<div className="date">{formatDate(playing.created_at)}</div>
		</div>
	);
};

export default PlayerPreview;
