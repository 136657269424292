import { gql } from 'graphql-tag';

const LeaderboardsGQL = {
	LeaderboardGQL: gql`
		query leaderboardSettings($id: Uint!) {
			leaderboardSettings(id: $id) {
				id
				name
				event_id
				start_time
				end_time
				rows
				individual
				show_stats
				show_averages
				show_members
				teams_count
				attendees {
					nodes {
						id
						email
						first_name
						last_name
						avatar_url
						metadata
						total_points
						place
					}
					stats {
						total_points
						participants
						actions
					}
				}
				teams {
					id
					name
					place
					attendees {
						page_info {
							total_count
						}
						stats {
							total_points
						}
					}
				}
			}
		}
	`,
	LeaderboardAttendeeGQL: gql`
		query leaderboardAttendee($id: Uint!, $attendee_id: Uint!) {
			leaderboardAttendee(id: $id, attendee_id: $attendee_id) {
				id
				name
				event_id
				individual
				attendee {
					id
					email
					first_name
					last_name
					avatar_url
					metadata
					total_points
					place
				}
				teams {
					id
					name
					place
					attendees {
						page_info {
							total_count
						}
						stats {
							total_points
						}
					}
				}
			}
		}
	`,
};

export default LeaderboardsGQL;
