import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { BellOutlined } from '@ant-design/icons';
import { LazyList } from '@blueprintnyc/bpnyc-common';
import { sortByTime, auditView } from 'common';
import { NotificationSubscriptions } from 'components/WebsocketSubscriptions';
import Notification from './Notification';
import { fetchNotifications, setAllNotificationsAsRead } from 'redux/actions';

import './Notifications.scss';

const Notifications = props => {
	const {
		event,
		user,
		notifications,
		setNotifications,
		setNotificationsAsRead,
		truncate = false,
	} = props;
	const navigate = useNavigate();
	const [isMounted, setIsMounted] = useState(false);
	const [toggleRerender, setToggleRerender] = useState(0);

	useEffect(() => {
		setIsMounted(true);
		auditView('notifications');
		return () => {
			setIsMounted(false);
			console.log('Set notifications as read.');
			setNotificationsAsRead(user.id, event.id);
		};
	}, []);

	useEffect(() => {
		if (isMounted) {
			const attendeeID = user.id;
			const eventID = event.id;
			const from = 'useEffect.Notifications';
			setNotifications(attendeeID, eventID, from);
		}
	}, [isMounted, event.id, user.id, setNotifications]);

	useEffect(() => {
		setToggleRerender(prevToggleRerender => prevToggleRerender + 1);
	}, [notifications]);

	const visitNotifications = () => {
		navigate(`/notifications`);
	};

	const isToday = data => {
		return moment(data.created_at).isSame(new Date(), 'day');
	};

	const renderNotifications = notifs => {
		return notifs.sort(sortByTime('created_at')).map((data, i) => {
			return <Notification key={i} data={data} />;
		});
	};

	let newNotifications;
	let olderNotifications;
	let wasTruncated = false;
	if (Object.values(notifications).length) {
		let properNotifications = Object.values(notifications)
			.filter(
				data =>
					data && typeof data === 'object' && data.event_id === event.id && (data.name || data.body)
			)
			.sort(sortByTime('created_at'));

		if (truncate && properNotifications.length >= truncate) {
			wasTruncated = true;
			properNotifications = properNotifications.slice(0, truncate);
		}

		newNotifications = renderNotifications(properNotifications.filter(data => isToday(data)));
		olderNotifications = renderNotifications(properNotifications.filter(data => !isToday(data)));
	}

	const hasNew = newNotifications && newNotifications.length;
	const hasOld = olderNotifications && olderNotifications.length;

	return (
		<NotificationSubscriptions eventID={event.id} attendeeID={user.id}>
			<div className="notificationsContainer">
				<div className="notifications">
					{hasNew ? <div className="section-header">TODAY</div> : null}
					{hasNew ? (
						<LazyList
							renderedElements={newNotifications}
							options={{ chunkSize: 10, trigger: 7 }}
							key={`new-notifications-${toggleRerender}`}
						/>
					) : null}
					{hasOld && hasNew ? <div className="section-header">OLDER</div> : null}
					{hasOld ? (
						<LazyList
							renderedElements={olderNotifications}
							options={{ chunkSize: 10, trigger: 7 }}
							key={`old-notifications-${toggleRerender}`}
						/>
					) : null}
					{wasTruncated ? (
						<div className="view-all" onClick={visitNotifications}>
							View All
						</div>
					) : null}
					{!hasNew && !hasOld ? (
						<div className="no-activity">
							<div className="icon">
								<BellOutlined />
							</div>
							<div className="header">No Recent Activity</div>
							<div className="description">Head to the social feed to get things started</div>
						</div>
					) : null}
				</div>
			</div>
		</NotificationSubscriptions>
	);
};

const mapStateToProps = ({ events, user, notifications }) => {
	return {
		event: events.event,
		user: user.user,
		notifications,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setNotifications: (attendeeID, eventID, from) =>
			dispatch(fetchNotifications(attendeeID, eventID, from)),
		setNotificationsAsRead: (attendeeID, eventID) =>
			dispatch(setAllNotificationsAsRead(attendeeID, eventID)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
