import React, { useEffect, useCallback, useState, memo } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Drawer, Layout } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { isMobile, isJson, backButton } from '../../common/';
import Sidebar from '../Sidebar';
import HeaderAvatar from 'components/HeaderAvatar';

import './index.scss';

const { Header } = Layout;

const AppHeader = memo(props => {
	const { back, event, title, domainBranding } = props;
	const { ASSETS_FOLDER, NAV_LOGO, LOGO, APP_NAME } = domainBranding;

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const [appHeaderState, setAppHeaderState] = useState({
		collapsed: false,
		visible: false,
		placement: 'left',
		isMounted: false,
	});

	const pagePath = pathname;
	const logoPath = NAV_LOGO ? NAV_LOGO : LOGO ? LOGO : '/' + ASSETS_FOLDER + 'logo.png';

	let view = pathname.includes('/mediaStackSingle')
		? 'mediaMaterial'
		: pagePath.includes('/mediaStack')
		? 'media'
		: pagePath.includes('/pageStack')
		? 'page'
		: pagePath.includes('/libraryStack')
		? 'library'
		: pagePath.includes('/podcasts/')
		? 'podcasts'
		: null;

	if (typeof isJson(event.theme) == 'object') {
		var { headerText, headerBg } = isJson(event.theme);
	}

	const showDrawer = () => {
		setAppHeaderState(prevState => ({ ...prevState, visible: true }));
	};

	const onClose = () => {
		setAppHeaderState(prevState => ({ ...prevState, visible: false }));
	};

	const resize = useCallback(() => {
		let isMobileDevice = isMobile();
		if (appHeaderState.isMounted) {
			setAppHeaderState(prevState => ({ ...prevState, hideNav: isMobileDevice }));
		}
	}, [appHeaderState.isMounted]);

	useEffect(() => {
		setAppHeaderState(prevState => ({ ...prevState, isMounted: true }));
		window.addEventListener('resize', resize.bind(this));
		resize();
		return () => setAppHeaderState(prevState => ({ ...prevState, isMounted: false }));
	}, [resize]);

	return (
		<div className="appHeader" style={{ backgroundColor: headerBg }}>
			{isMobile() ? null : (
				<div onClick={() => navigate('/dashboard')} className="logo">
					<Link to="/dashboard">
						<img src={logoPath} alt="Logo" />
					</Link>
				</div>
			)}
			<Header style={{ backgroundColor: headerBg }}>
				<div id="innerHeaderContainer" style={{ color: headerText }}>
					{view && (back || !appHeaderState.hideNav)
						? backButton(navigate, view, headerText)
						: null}
					{APP_NAME !== 'Team USA Tour' && !view && appHeaderState.hideNav && (
						<MenuOutlined
							className="headerMenuIcon"
							style={{ color: headerText }}
							onClick={showDrawer}
						/>
					)}
					{APP_NAME !== 'Team USA Tour' ? (
						<>
							<h1 data-testid="titleTest" style={{ color: headerText }}>
								{title ? title : 'Home'}
							</h1>
							<div className="headerRightIcon">
								<HeaderAvatar />
							</div>
						</>
					) : (
						<div style={{ width: '100%' }}></div>
					)}
				</div>
			</Header>
			<Drawer
				className="drawer"
				placement={appHeaderState.placement}
				closable={false}
				onClose={onClose}
				open={appHeaderState.visible}
				width={'220px'}
			>
				<Sidebar
					style={{ backgroundColor: headerBg }}
					className="menu"
					{...props}
					onClose={onClose}
				/>
			</Drawer>
		</div>
	);
});

const mapStateToProps = state => {
	return {
		event: state.events.event,
		domainBranding: state.domains.domainBranding,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
