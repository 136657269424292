import { FeedsServices } from '../../services';

export const SET_FEEDS = 'FEEDS';
export const POST = 'POST';
export const DELETE_POST = 'DELETE_POST';
export const LIKE = 'LIKE';
export const COMMENT = 'COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const EMPTY_POST = 'EMPTY_POST';
export const SET_PINNED_POSTS = 'SET_PINNED_POSTS';
export const EMPTY_FEED = 'EMPTY_FEED';
// Setters
export const setFeeds = (feeds, offset) => ({
	type: SET_FEEDS,
	data: { feeds, offset },
});

export const emptyPost = () => ({
	type: EMPTY_POST,
});

export const deletePost = post_id => ({
	type: DELETE_POST,
	data: { post_id },
});

export const setPost = post => ({
	type: POST,
	data: { post },
});

export const setComment = comment => ({
	type: COMMENT,
	data: { comment },
});

export const deleteComment = comment_id => ({
	type: DELETE_COMMENT,
	data: { comment_id },
});

export const setLike = like => ({
	type: LIKE,
	data: { like },
});

export const setPinnedPosts = pinned => ({
	type: SET_PINNED_POSTS,
	pinned,
});

export const emptyFeed = () => ({
	type: EMPTY_FEED,
});

// Getters
export const getFeedsAction = (
	event_id,
	offset,
	items_per_page,
	search_query,
	filter
) => async dispatch => {
	let { error, data } = await FeedsServices.GetFeeds(
		event_id,
		offset,
		items_per_page,
		search_query,
		filter
	);
	if (data) {
		dispatch(setFeeds(data, offset));
		return data;
	} else {
		return error.message;
	}
};

export const postFeedAction = query => async dispatch => {
	let { error, data } = await FeedsServices.PostFeed(query);
	if (data) {
		dispatch(setPost(data.createPost));
		return data;
	} else {
		return error.message;
	}
};

export const emptyPostAction = () => async dispatch => {
	return dispatch(emptyPost());
};

export const updateFeedAction = query => async dispatch => {
	let { error, data } = await FeedsServices.UpdateFeed(query);
	dispatch(setPost(data.updatePost));
	return data ? data : error;
};

export const deletePostAction = id => async dispatch => {
	let { error, data } = await FeedsServices.DeleteFeed(id);
	if (data && data.deletePost) {
		dispatch(deletePost(data.deletePost.id));
		return data;
	} else {
		return error;
	}
};

export const getPostAction = query => async dispatch => {
	let { error, data } = await FeedsServices.GetPost(query);
	dispatch(setPost(data.post));
	return data ? data : error.message;
};

export const postCommentAction = query => async dispatch => {
	let { error, data } = await FeedsServices.PostComment(query);
	if (data && data.createComment) {
		dispatch(setComment({ ...query.post, ...data.createComment }));
		return data;
	} else {
		return error;
	}
};

export const deleteCommentAction = commentID => async dispatch => {
	const { error, data } = await FeedsServices.DeleteComment(commentID);
	if (data && data.deletePost) {
		dispatch(deleteComment(data.deletePost.id));
		return data;
	} else {
		return error;
	}
};

export const postFeedLikeAction = variables => async dispatch => {
	const { post_id, user, increment } = variables;
	let { error, data } = await FeedsServices.PostFeedLike({
		post_id,
		attendee_id: user.id,
		increment,
	});
	if (data && data.likePost) {
		dispatch(setLike(variables));
		return data;
	} else {
		return error.message;
	}
};

export const getPinnedPosts = event_id => async dispatch => {
	const { error, data } = await FeedsServices.getPinnedPosts(event_id);
	if (data) {
		const pinnedPosts = data.pinnedPosts.nodes;
		dispatch(setPinnedPosts(pinnedPosts));
		return pinnedPosts;
	} else return error;
};

export const emptyFeedAction = () => async dispatch => {
	return dispatch(emptyFeed());
};
