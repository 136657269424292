import GraphQLController from './GraphQL';
import EventsGQL from './gql/EventsGQL';

const EventsServices = {
	events: async () => {
		// let variables = {email, password, client: AppConfig.CLIENT_ID};
		let mutation = EventsGQL.Events;
		let result = await GraphQLController.Query(mutation);
		return result;
	},
	event: async id => {
		let variables = { id };
		let mutation = EventsGQL.Event;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	eventPoints: async event_id => {
		let variables = { event_id };
		let mutation = EventsGQL.EventPoints;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	eventTheme: async event_id => {
		let variables = { event_id };
		let mutation = EventsGQL.EventTheme;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
};

export default EventsServices;
