import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updatePodcastAsPlayed, removePodcastAsPlayed } from 'redux/actions';
import { Popover } from 'antd';
import { MenuHorizontal, CheckCircle, CloseCircle } from '@blueprintnyc/bpnyc-common-admin/icons';

import './PodcastActionsMenu.scss';

const PodcastActionsMenu = props => {
	const {
		podcast,
		attendeeID,
		playedByAttendee,
		updatePodcastAsPlayed,
		removePodcastAsPlayed,
	} = props;

	const [visible, setVisible] = useState(false);

	const TogglePodcastPlayedStatus = () => {
		if (playedByAttendee?.length) {
			removePodcastAsPlayed(podcast.id, attendeeID);
		} else updatePodcastAsPlayed(podcast.id, attendeeID);

		setVisible(false);
	};

	const togglePlayedStatusDiv = (
		<div
			className="toggle-played-btn"
			onClick={TogglePodcastPlayedStatus}
			style={{ cursor: 'pointer' }}
		>
			{playedByAttendee?.length ? (
				<div className="action-opt">
					<CloseCircle />
					<div>Mark as unplayed</div>
				</div>
			) : (
				<div className="action-opt">
					<CheckCircle /> <div>Mark as played</div>
				</div>
			)}
		</div>
	);

	return (
		<div className="podcast-actions-menu">
			<Popover
				content={togglePlayedStatusDiv}
				trigger="click"
				placement="right"
				open={visible}
				onOpenChange={isOpen => {
					if (isOpen) {
						return;
					}
					setVisible(false);
				}}
			>
				<MenuHorizontal className="icon-menu" onClick={() => setVisible(true)} />
			</Popover>
		</div>
	);
};

const mdp = dispatch => ({
	updatePodcastAsPlayed: (podcastID, attendeeID) =>
		dispatch(updatePodcastAsPlayed(podcastID, attendeeID)),
	removePodcastAsPlayed: (podcastID, attendeeID) =>
		dispatch(removePodcastAsPlayed(podcastID, attendeeID)),
});

export default connect(null, mdp)(PodcastActionsMenu);
