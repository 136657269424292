import GraphQLController from './GraphQL';
import { Constants } from '../common';
import DirectoryGQL from './gql/DirectoryGQL';

const DirectoryServices = {
	Directories: async params => {
		const { event_id, search_term, offset, items_per_page } = params;
		let variables = { event_id, search_term, offset, items_per_page, client: Constants.CLIENT_ID };
		let mutation = DirectoryGQL.Directories;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	Attendee: async id => {
		let variables = { id, client: Constants.CLIENT_ID };
		let mutation = DirectoryGQL.Attendee;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	EventAttendee: async (id, event_id) => {
		let variables = { id, event_id, client: Constants.CLIENT_ID };
		let mutation = DirectoryGQL.EventAttendee;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	EventAttendeeMetadata: async (id, event_id) => {
		let variables = { id, event_id, client: Constants.CLIENT_ID };
		let mutation = DirectoryGQL.EventAttendeeMetadata;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	EventAttendeePoints: async (attendee_id, event_id) => {
		let variables = { attendee_id, event_id, client: Constants.CLIENT_ID };
		let mutation = DirectoryGQL.EventAttendeePoint;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
};

export default DirectoryServices;
