import { gql } from 'graphql-tag';

const KudosGQL = {
	KudosListGQL: gql`
		query attendeeKudosList($event_id: Uint!, $attendee_id: Uint!) {
			attendeeKudosList(event_id: $event_id, attendee_id: $attendee_id) {
				kudos {
					id
					src
					type
					name
					limit
					active
					kudo_givens {
						attendee_giver_id
					}
					groups {
						id
						name
						event_id
					}
				}
				badges {
					id
					src
					type
					name
				}
			}
		}
	`,
	LikeDislikeKudosGQL: gql`
		mutation likeAndDislikeKudo(
			$kudo_id: Uint!
			$attendee_receiver_id: Uint!
			$attendee_giver_id: Uint!
			$is_like: Boolean!
		) {
			likeAndDislikeKudo(
				kudo_id: $kudo_id
				attendee_receiver_id: $attendee_receiver_id
				attendee_giver_id: $attendee_giver_id
				is_like: $is_like
			) {
				id
				count
			}
		}
	`,
};

export default KudosGQL;
