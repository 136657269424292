import React from 'react';
import SubscriptionTemplate from './SubscriptionTemplate';
import { connect } from 'react-redux';
import { setLeaderboardRefetch } from 'redux/actions';

const WS_EVENTS_BY_HTTP = {
	UPDATE_LEADERBOARD: 'event/:eventID/leaderboards/:leaderboardID/PATCH',
};

const LeaderboardSubscriptions = props => {
	const { socket, leaderboardID, refetchLeaderboard } = props;
	console.log('Leaderboard Subscriptions???');
	const propsToSend = { ...props };
	delete propsToSend.children;
	delete propsToSend.eventID;
	const subscribe = () => {
		console.log('⚡️ AppLeaderboardSubscriptions Mounted ⚡️');
		if (leaderboardID) socket.emit('app/leaderboard/:leaderboardID/SUBSCRIBE', { leaderboardID });
	};

	const unsubscribe = prevLeaderboardID => {
		console.log(`🚶‍♂️ AppLeaderboardSubscriptions Unmounted 🚶‍♂️`);
		if (prevLeaderboardID)
			socket.emit('app/leaderboard/:leaderboardID/UNSUBSCRIBE', {
				leaderboardID: prevLeaderboardID,
			});
	};

	const listenForUpdatedLeaderboard = () => {
		socket.on(WS_EVENTS_BY_HTTP.UPDATE_LEADERBOARD, payload => {
			const { data, error } = payload;
			if (error) console.log('Error updating leaderboard', error);
			else {
				refetchLeaderboard('listenForUpdatedLeaderboard');
				console.log('This leaderboard has been updated!', data);
			}
		});
	};

	const listeners = () => [listenForUpdatedLeaderboard];

	return (
		<>
			<SubscriptionTemplate
				{...props}
				sub={subscribe}
				unsub={prevLeaderboardID => unsubscribe(prevLeaderboardID)}
				listeners={listeners()}
				routes={Object.values(WS_EVENTS_BY_HTTP)}
				from={'leaderboard'}
			/>
			{React.cloneElement(props.children, propsToSend)}
		</>
	);
};

const msp = state => ({
	socket: state.ws.socket,
});

const mdp = dispatch => ({
	refetchLeaderboard: from => dispatch(setLeaderboardRefetch(from)),
});

export default connect(msp, mdp)(LeaderboardSubscriptions);
