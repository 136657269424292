import React from 'react';
import ProgressiveImage from 'components/ProgressiveImage';

import './ProgressiveAvatar.scss';

const getFirstLetter = word => {
	return word && word.length ? word.charAt(0) : '';
};

const avatarBgColors = [
	'#E53F6F',
	'#613CB0',
	'#D9534F',
	'#3F51B5',
	'#4994EC',
	'#9031AA',
	'#235696',
	'#62B1F6',
	'#5CB85C',
	'#97C15C',
	'#D0DC59',
	'#FCEC60',
	'#F6C344',
	'#F0AD4E',
];

const avatarColor = userID => avatarBgColors[userID % avatarBgColors.length];

const ProgressiveAvatar = props => {
	const { attendee, alt, style, size, shape, hasBorder } = props;
	const src = attendee.avatar_url;

	const initials =
		attendee && typeof attendee === 'object' && !src
			? attendee.name
				? attendee.name.slice(0, 2)
				: `${getFirstLetter(attendee.first_name)}${getFirstLetter(attendee.last_name)}`
			: typeof attendee === 'string'
			? attendee
			: '';

	const widthHeight = size ? `${size}px` : null;
	const fontSize = size ? `${Math.round(size * 0.4)}px` : null;
	const dimensionStyles = size ? { height: widthHeight, width: widthHeight, fontSize } : {};
	const shapeStyles = !(shape && shape === 'square') ? { borderRadius: '50%' } : {};
	const avatarStyles = { ...style, ...dimensionStyles, ...shapeStyles };
	const bgColor = attendee.color ? `${attendee.color}44` : avatarColor(attendee.id);
	const attendeeIcon = attendee.icon ? <div className="icon">{attendee.icon}</div> : null;

	return (
		<div className="ProgressiveAvatar" style={{ ...style, ...dimensionStyles }}>
			{src ? (
				<ProgressiveImage
					src={src}
					alt={alt}
					style={{ ...avatarStyles, outline: hasBorder ? '2px solid #FFFFFF' : 'none' }}
				/>
			) : (
				<div
					className="initials"
					style={{
						...avatarStyles,
						backgroundColor: bgColor,
						color: attendee.color || '#FFFFFF',
						outline: hasBorder ? '2px solid #FFFFFF' : 'none',
					}}
				>
					{attendeeIcon || initials}
				</div>
			)}
		</div>
	);
};

export default ProgressiveAvatar;
