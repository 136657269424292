import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearPodcast, fetchPodcast } from 'redux/actions';
import { PodcastDetail } from 'components/Podcasts';

import './Podcast.scss';

const Podcast = props => {
	const { podcast, event, fetchPodcast, updatePlaying, setPageTitle, clearPodcast } = props;
	const theme = JSON.parse(event.theme || '{}');
	const { id: podcastID } = useParams();

	useEffect(() => {
		if (podcastID) {
			fetchPodcast(podcastID);
		}

		return () => {
			clearPodcast();
		};
	}, [podcastID]);

	useEffect(() => {
		if (podcast && podcast.name) setPageTitle(podcast.name || 'Podcast Episode');
	}, [podcast]);

	return (
		<div className="podcast-layout-container">
			<PodcastDetail podcast={podcast} theme={theme} updatePlaying={updatePlaying} />
		</div>
	);
};

const msp = ({ context, events, podcasts }) => ({
	eventID: context.currentEventID,
	podcast: podcasts.podcast,
	event: events.event,
	playing: podcasts.playing.podcast,
	paused: podcasts.playing.paused,
});

const mdp = dispatch => ({
	fetchPodcast: async podcastID => dispatch(fetchPodcast(podcastID)),
	clearPodcast: async () => dispatch(clearPodcast()),
});

export default connect(msp, mdp)(Podcast);
