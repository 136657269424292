import { batch } from 'react-redux';
import { EventsServices } from '../../services';
import AnalyticsService, { Enums } from '../../services/AnalyticsService';
import { analyticsActions } from '../AnalyticsRedux';

export const SET_EVENT = 'SET_EVENT';
export const SET_EVENTS = 'SET_EVENTS';
export const CLEAR_EVENT = 'CLEAR_EVENT';
export const SET_PUBLIC_EVENT_THEME = 'SET_PUBLIC_EVENT_THEME';

//Setters
export const setEvent = event => ({
	type: SET_EVENT,
	event,
});

const setEvents = events => ({
	type: SET_EVENTS,
	events,
});

export const clearEvent = () => ({
	type: CLEAR_EVENT,
});

export const setPublicEventTheme = theme => ({
	type: SET_PUBLIC_EVENT_THEME,
	theme,
});

//Getters
export const fetchEvent = eventID => async (dispatch, getState) => {
	let event = await EventsServices.event(eventID);
	let eventPoint = await EventsServices.eventPoints(eventID);

	if (event.error || eventPoint.error) {
		return `Event Error: ${event.error} \n Event Point Error: ${eventPoint.error}`;
	}

	let analyticsAction;
	const { analytics, user: stateUser } = getState();
	const { user } = stateUser;

	if (user && event && !analytics.ready) {
		const { analytics_id } = event.data.event;
		if (analytics_id) {
			const { client_id, company, email, first_name, id, last_name } = user;
			console.log('Analytics id', analytics_id);
			const analytics = AnalyticsService(analytics_id);
			// analytics.reset()
			analytics.event(Enums.login, {
				eventID,
				client_id,
				company,
				email,
				first_name,
				id,
				last_name,
			});
			analyticsAction = analyticsActions.setAnalytics(analytics);
		}
	}

	event.data.event['points'] = eventPoint.data.eventPoint;
	const eventData = event.data.event;
	batch(() => {
		dispatch(setEvent(eventData));
		if (analyticsAction) dispatch(analyticsAction);
	});
};

export const fetchEvents = () => async dispatch => {
	let { error, data } = await EventsServices.events();
	let eventsWithoutDuplicates;
	if (data) {
		eventsWithoutDuplicates = data.me.events.filter(
			(event, i) => data.me.events.indexOf(event) === i
		);
	}
	return data ? dispatch(setEvents(eventsWithoutDuplicates)) : error.message;
};

export const fetchPublicEventTheme = eventID => async dispatch => {
	let { error, data } = await EventsServices.eventTheme(eventID);
	if (data && !data.eventTheme.theme) return null;
	return data ? dispatch(setPublicEventTheme(data.eventTheme.theme)) : error.message;
};
