import { gql } from 'graphql-tag';

const PodcastsGQL = {
	Podcasts: gql`
		query podcasts($event_id: Uint) {
			podcasts(event_id: $event_id) {
				id
				event_id
				name
				description
				media_src
				art_src
				hidden
				duration
				mime_type
				listens
				pinned
				groups {
					id
					name
				}
				played_by {
					id
					email
					first_name
					last_name
				}
				created_at
				updated_at
			}
		}
	`,
	Podcast: gql`
		query podcast($id: Uint) {
			podcast(id: $id) {
				id
				event_id
				name
				description
				media_src
				art_src
				hidden
				duration
				mime_type
				listens
				pinned
				groups {
					id
					name
				}
				played_by {
					id
					email
					first_name
					last_name
				}
				created_at
				updated_at
			}
		}
	`,
	UpdatePodcastListens: gql`
		mutation updatePodcastListens($id: Uint!, $listens: Uint!) {
			updatePodcastListens(id: $id, listens: $listens) {
				id
				event_id
				name
				description
				media_src
				art_src
				hidden
				duration
				mime_type
				listens
				pinned
				groups {
					id
					name
				}
				played_by {
					id
					email
					first_name
					last_name
				}
				created_at
				updated_at
			}
		}
	`,
	MarkPodcastAsPlayed: gql`
		mutation markPodcastAsPlayed($podcast_id: Uint!, $attendee_id: Uint!) {
			markPodcastAsPlayed(podcast_id: $podcast_id, attendee_id: $attendee_id) {
				id
				event_id
				name
				description
				media_src
				art_src
				hidden
				duration
				mime_type
				listens
				pinned
				groups {
					id
					name
				}
				played_by {
					id
					email
					first_name
					last_name
				}
				created_at
				updated_at
			}
		}
	`,
	MarkPodcastAsUnplayed: gql`
		mutation markPodcastAsUnplayed($podcast_id: Uint!, $attendee_id: Uint!) {
			markPodcastAsUnplayed(podcast_id: $podcast_id, attendee_id: $attendee_id) {
				id
				event_id
				name
				description
				media_src
				art_src
				hidden
				duration
				mime_type
				listens
				pinned
				groups {
					id
					name
				}
				played_by {
					id
					email
					first_name
					last_name
				}
				created_at
				updated_at
			}
		}
	`,
};

export default PodcastsGQL;
