import React from 'react';
import { connect } from 'react-redux';
import { IoIosArrowBack } from 'react-icons/io';
import { Play, Pause } from '@blueprintnyc/bpnyc-common-admin/icons';
import { PodcastMediaPreview } from '../';

import './UpNext.scss';

const UpNext = props => {
	const { playing, podcasts, setView, theme, updatePlaying, paused } = props;

	const handleUpdatePlaying = () => updatePlaying(playing);

	return playing && playing.id ? (
		<div
			className="up-next-container"
			style={{ color: theme.headerText, background: theme.headerBg }}
		>
			<div className="currently-playing-container">
				<div className="section-header">
					<div className="back-btn" style={{ background: theme.brandPrimary }}>
						<IoIosArrowBack onClick={() => setView('playing')} />
					</div>
					<div>Currently Playing</div>
				</div>
				<div className="currently-playing">
					<div className="podcast-info">
						<img className="cover-art" src={playing.art_src} alt="podcast-cover-art" />
						<div className="podcast-title">{playing.name}</div>
					</div>
					<div className="play-pause-btn" onClick={handleUpdatePlaying}>
						{!paused ? <Pause /> : <Play />}
					</div>
				</div>
			</div>
			<div className="up-next-podcasts">
				<div className="section-header">
					<div>Up Next</div>
				</div>
				{podcasts && podcasts.length
					? podcasts.map((podcast, i) => (
							<div key={i} className="up-next-podcast">
								<PodcastMediaPreview
									podcast={podcast}
									theme={theme}
									type="up-next"
									updatePlaying={updatePlaying}
								/>
							</div>
					  ))
					: 'No podcasts in the Up Next playlist.'}
			</div>
		</div>
	) : null;
};

const msp = ({ podcasts }) => ({
	paused: podcasts.playing.paused,
});

export default connect(msp, null)(UpNext);
