import { useEffect } from 'react';

const SubscriptionTemplate = ({
	sub,
	unsub,
	listeners,
	socket,
	routes,
	from,
	eventID,
	attendeeID,
	groupIDsRoom,
	libraryID,
}) => {
	useEffect(() => {
		subAndAddListeners();
		listenForConnectChange();
		return () => {
			unsubAndRemoveListeners();
		};
	}, []);

	useEffect(() => {
		if (!(from === 'event' && eventID && eventID === 'LOGOUT')) {
			sub();
		}
		if (from === 'groups') {
			subAndAddListeners();
		}
		return () => {
			unsub(eventID);
			if (from === 'groups') {
				removeListeners();
			}
		};
	}, [eventID]);

	useEffect(() => {
		sub();
		return () => {
			unsub(attendeeID);
		};
	}, [attendeeID]);

	useEffect(() => {
		sub();
		return () => {
			unsub(groupIDsRoom);
		};
	}, [groupIDsRoom]);

	useEffect(() => {
		sub();
		return () => {
			unsub(libraryID);
		};
	}, [libraryID]);

	const listenForConnectChange = () => {
		socket.on('disconnect', reason => {
			if (reason === 'transport close') {
				console.log('Disconnect - unsubAndRemoveListeners', from);
				unsubAndRemoveListeners();
			}
		});
		socket.on('connect', () => {
			console.log('connect - subAndAddListeners', from);
			subAndAddListeners();
		});
	};

	const addListeners = () => {
		console.log('addlisteners', from);
		console.log(routes, listeners);
		listeners && listeners.forEach(listener => listener());
	};
	const removeListeners = () => {
		routes.forEach(route => {
			socket.off(route);
		});
	};

	const subAndAddListeners = () => {
		console.log('subAndAddListeners', from);
		sub();
		addListeners();
	};

	const unsubAndRemoveListeners = () => {
		console.log('unsubAndRemoveListeners', from);
		unsub();
		removeListeners();
	};

	return null;
};

export default SubscriptionTemplate;
