import React from 'react';
import SubscriptionTemplate from './SubscriptionTemplate';
import { connect } from 'react-redux';
import {
	addKudos,
	updateKudos,
	deleteKudos,
	addGroupsToKudos,
	removeGroupsFromKudos,
	updateBadgeForAssignee,
	likeAndDislikeKudo,
} from 'redux/actions';

const WS_KUDOS_BY_HTTP = {
	//Kudos Updates
	CREATE_KUDO_BADGE: 'kudos/POST',
	UPDATE_KUDO_BADGE: 'kudos/:kudosID/PATCH',
	DELETE_KUDO_BADGE: 'kudos/:kudosID/DELETE',
	ASSIGN_BADGE_TO_ATTENDEE: 'attendee/:attendeeID/badges/:badgeID',
	ADD_GROUP_TO_KUDO: 'kudos/:kudosID/addGroups/PATCH',
	REMOVE_GROUP_FROM_KUDO: 'kudos/:kudosID/removeGroups/PATCH',
	LIKE_DISLIKE_KUDO: 'attendee/:attendeeID/kudos/likeDislike/PATCH',
};

const KudosSubscriptions = props => {
	const {
		socket,
		eventID,
		attendeeID,
		createKudos,
		updateKudos,
		deleteKudos,
		addGroups,
		removeGroups,
		updateBadgeForAssignee,
		likeAndDislikeKudo,
		kudos,
	} = props;

	const propsToSend = { ...props };
	delete propsToSend.children;
	const subscribe = () => {
		console.log('⚡️ KudosSubscriptions Mounted ⚡️');
		socket.emit('app/event/:eventID/kudos/SUBSCRIBE', { eventID, attendeeID });
	};

	const unsubscribe = prevAttendeeID => {
		console.log(`🚶‍♂️ KudosSubscriptions Unmounted 🚶‍♂️`);
		socket.emit('app/event/:eventID/kudos/UNSUBSCRIBE', {
			eventID,
			attendeeID: prevAttendeeID || attendeeID,
		});
	};

	const listenForCreatedKudo = () => {
		socket.on(WS_KUDOS_BY_HTTP.CREATE_KUDO_BADGE, payload => {
			const { data, error } = payload;
			if (error) console.log(`Error creating kudo`, error);
			else {
				const { name } = data;
				const { kudosOrBadges } = payload;
				if (kudosOrBadges === 'kudos') {
					createKudos(data, kudosOrBadges);
					console.log(`${name} created`, data);
				}
			}
		});
	};

	const listenForUpdatedKudo = () => {
		socket.on(WS_KUDOS_BY_HTTP.UPDATE_KUDO_BADGE, payload => {
			const { data, error } = payload;
			if (error) console.log(`Error creating kudo`, error);
			else {
				const { name } = data;
				const { kudosOrBadges } = payload;

				updateKudos(data, kudosOrBadges);
				console.log(`${name} updated`, data, kudosOrBadges);
			}
		});
	};

	const listenForDeletedKudo = () => {
		socket.on(WS_KUDOS_BY_HTTP.DELETE_KUDO_BADGE, payload => {
			const { data, error } = payload;
			if (error) console.log(`Error deleting kudo`, error);
			else {
				const { kudosName, deleteKudo } = data;
				const { kudosOrBadges } = payload;
				deleteKudos(deleteKudo.id, kudosOrBadges);
				console.log(`${kudosName} deleted`, data, kudosOrBadges);
			}
		});
	};

	const listenForAddedGroups = () => {
		socket.on(WS_KUDOS_BY_HTTP.ADD_GROUP_TO_KUDO, payload => {
			const { data, error } = payload;
			if (error) console.log('Groups could not be added', error);
			else {
				const { addManyGroupsToKudo, kudoID } = data;
				const { kudosOrBadges } = payload;
				if (kudosOrBadges === 'kudos') {
					addGroups(addManyGroupsToKudo, kudosOrBadges, kudoID);
					console.log('Added groups', kudosOrBadges, data);
				}
			}
		});
	};

	const listenForRemovedGroups = () => {
		socket.on(WS_KUDOS_BY_HTTP.REMOVE_GROUP_FROM_KUDO, payload => {
			const { data, error } = payload;
			if (error) console.log('Groups could not be removed', error);
			else {
				const { removeManyGroupsFromKudo, kudoID } = data;
				const { kudosOrBadges } = payload;
				if (kudosOrBadges === 'kudos') {
					removeGroups(removeManyGroupsFromKudo, kudosOrBadges, kudoID);
					console.log('Removed Groups', kudosOrBadges, data);
				}
			}
		});
	};

	const listenForUpdatedBadges = () => {
		socket.on(WS_KUDOS_BY_HTTP.ASSIGN_BADGE_TO_ATTENDEE, payload => {
			const { data, error } = payload;
			if (error) console.log('Badge could not be updated', error);
			else {
				const { badge } = data;
				updateBadgeForAssignee(badge);
				console.log('Badge saved', data);
			}
		});
	};

	const listenForLikedDislikedKudo = () => {
		socket.on(WS_KUDOS_BY_HTTP.LIKE_DISLIKE_KUDO, payload => {
			const { data, error } = payload;
			if (error) {
				const responseError = error.response.errors[0].message;
				const kudoLimitMsg = 'kudo limit exceeded';
				const kudoID = error.request.variables.kudo_id;
				const kudo = kudos.find(kudo => kudoID === kudo.id);
				if (responseError === kudoLimitMsg)
					alert(
						`You have already given ${kudo.name} the maximum number of times. Limit ${kudo.limit} times.`
					);
				console.log('Kudo could not be updated', error);
			} else {
				const { kudo_id, attendee_receiver_id, attendee_giver_id, is_like } = data;
				const kudoGiven = { attendee_receiver_id, attendee_giver_id };
				likeAndDislikeKudo(kudo_id, is_like, kudoGiven);
			}
		});
	};

	const listeners = () => [
		listenForCreatedKudo,
		listenForUpdatedKudo,
		listenForDeletedKudo,
		listenForAddedGroups,
		listenForRemovedGroups,
		listenForUpdatedBadges,
		listenForLikedDislikedKudo,
	];

	return (
		<>
			<SubscriptionTemplate
				{...props}
				sub={subscribe}
				unsub={unsubscribe}
				listeners={listeners()}
				routes={Object.values(WS_KUDOS_BY_HTTP)}
				from={'kudos'}
			/>
			{React.cloneElement(props.children, propsToSend)}
		</>
	);
};

const msp = ({ ws, context }) => ({
	socket: ws.socket,
	eventID: context.currentEventID,
});

const mdp = dispatch => ({
	createKudos: (kudo, kudosOrBadges) => dispatch(addKudos(kudo, kudosOrBadges)),
	updateKudos: (kudo, kudosOrBadges) => dispatch(updateKudos(kudo, kudosOrBadges)),
	deleteKudos: (kudoID, kudosOrBadges) => dispatch(deleteKudos(kudoID, kudosOrBadges)),
	addGroups: (groups, kudosOrBadges, kudoID) =>
		dispatch(addGroupsToKudos(groups, kudosOrBadges, kudoID)),
	removeGroups: (groups, kudosOrBadges, kudoID) =>
		dispatch(removeGroupsFromKudos(groups, kudosOrBadges, kudoID)),
	updateBadgeForAssignee: badge => dispatch(updateBadgeForAssignee(badge)),
	likeAndDislikeKudo: (kudoID, isLiked, kudoGiven) =>
		dispatch(likeAndDislikeKudo(kudoID, isLiked, kudoGiven)),
});

export default connect(msp, mdp)(KudosSubscriptions);
