import GraphQLController from './GraphQL';
import NotificationPreferencesGQL from './gql/NotificationPreferencesGQL';

const NotificationPreferencesServices = {
	notificationTypes: async () => {
		let mutation = NotificationPreferencesGQL.NotificationTypes;
		let result = await GraphQLController.Query(mutation);
		return result;
	},
	eventNotificationPreferences: async ({ event_id }) => {
		let variables = { event_id };
		let mutation = NotificationPreferencesGQL.EventNotificationPreferences;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	attendeeNotificationPreferences: async ({ event_id, attendee_id }) => {
		let variables = { event_id, attendee_id };
		let mutation = NotificationPreferencesGQL.AttendeeNotificationPreferences;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
};

export default NotificationPreferencesServices;
