import GraphQLController from './GraphQL';
import { Constants } from '../common';
import LibrariesGQL from './gql/LibrariesGQL';

const LibraryServices = {
	libraryService: async id => {
		let variables = { id, client: Constants.CLIENT_ID };
		let result = await GraphQLController.Query(LibrariesGQL.LibraryGQL, variables);
		return result;
	},
	attendeeMaterialsService: async eventID => {
		let variables = { event_id: eventID };
		let result = await GraphQLController.Query(LibrariesGQL.AttendeeMaterialsGQL, variables);
		console.log(result);
		return result;
	},
	mediaService: async vars => {
		const { id, event_id } = vars;
		let variables = { id, event_id, client: Constants.CLIENT_ID };
		let result = await GraphQLController.Query(LibrariesGQL.MediaGQL, variables);
		console.log(result);
		return result;
	},
};

export default LibraryServices;
