import { gql } from 'graphql-tag';

const LibrariesGQL = {
	LibraryGQL: gql`
		query library($id: Uint) {
			library(id: $id) {
				id
				name
				description
				sort_order
				hidden
				libraries {
					id
					name
					hidden
					groups {
						id
						name
					}
				}
				media {
					nodes {
						id
						name
						src
						type
						hidden
						mime_type
						created_at
						updated_at
					}
				}
				groups {
					id
					name
				}
			}
		}
	`,
	AttendeeMaterialsGQL: gql`
		query attendeeMaterials($event_id: Uint) {
			attendeeMaterials(event_id: $event_id) {
				id
				name
				description
				sort_order
				hidden
				parent_library_id
				libraries {
					id
					name
					hidden
					groups {
						id
						name
					}
				}
				media {
					nodes {
						id
						name
						src
						type
						hidden
						mime_type
						created_at
						updated_at
					}
				}
				groups {
					id
					name
				}
			}
		}
	`,
	MediaGQL: gql`
		query media($event_id: Uint, $id: Uint) {
			media(event_id: $event_id, id: $id) {
				id
				event_id
				name
				src
				type
				hidden
				size
				mime_type
				created_at
				updated_at
			}
		}
	`,
};

export default LibrariesGQL;
