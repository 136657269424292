import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getDomainBranding } from '../../../src/AppConfig';

const BrandingProvider = ({ domain, setDomainBranding }) => {
	useEffect(() => {
		showBranding();
	}, [domain]);

	const showBranding = async () => {
		try {
			const domainBranding = await getDomainBranding(domain);
			setDomainBranding(domainBranding);
		} catch (e) {
			console.log(e);
		}
	};
	return null;
};

const mdp = dispatch => {
	const { domainActions } = require('../../redux/DomainRedux');
	return {
		domain: url => dispatch(domainActions.domain(url)),
		setDomainBranding: domainBranding => dispatch(domainActions.setDomainBranding(domainBranding)),
	};
};

export default connect(null, mdp)(BrandingProvider);
