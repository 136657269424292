import GraphQLController from './GraphQL';
import LeaderboardsGQL from './gql/LeaderboardsGQL';

const LeaderboardServices = {
	leaderboardService: async id => {
		let variables = { id };
		let result = await GraphQLController.Query(LeaderboardsGQL.LeaderboardGQL, variables);
		return result;
	},
	leaderboardAttendeeService: async (id, attendeeID) => {
		let variables = { id, attendee_id: attendeeID };
		let result = await GraphQLController.Query(LeaderboardsGQL.LeaderboardAttendeeGQL, variables);
		// console.log('leaderboardAtttendeeService', result);
		return result;
	},
};

export default LeaderboardServices;
