import Api from './ApiController';
import { routes } from './APIEndPoints';
import GraphQLController from './GraphQL';
import { Constants } from '../common';
import UserGQL from './gql/UserGQL';

const UserService = {
	login: async (email, password) => {
		let variables = { email, password, client: Constants.CLIENT_ID, device: 'webapp' };
		let mutation = UserGQL.Login;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
	register: async (attendee, event_id) => {
		let variables = { attendee, event_id, device: 'webapp' };
		let mutation = UserGQL.Register;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
	getUserData: async token => {
		let query = UserGQL.GetUserData;
		const variables = { token };
		const result = await GraphQLController.Query(query, variables);
		return result;
	},
	requestPasswordResetEmail: async (email, reset_url) => {
		let variables = {
			email,
			reset_url,
			client_id: Constants.CLIENT_ID,
			site_name: Constants.APP_NAME,
		};
		return await Api.delete(routes.passwordReset, variables);
	},
	resetPassword: async (password, token) => {
		let params = {
			password,
			token,
			client_id: Constants.CLIENT_ID,
		};
		let url = new URL(routes.passwordReset);
		url.search = new URLSearchParams(params).toString();
		return await Api.put(url, {});
	},
	authHistory: async authHistory => {
		let variables = { auth_history: authHistory };
		let mutation = UserGQL.AuthHistory;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
};

export default UserService;
