import GraphQLController from './GraphQL';
import PodcastsGQL from './gql/PodcastsGQL';

const PodcastsServices = {
	podcasts: async eventID => {
		let variables = { event_id: eventID };
		let mutation = PodcastsGQL.Podcasts;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	podcast: async id => {
		let variables = { id };
		let mutation = PodcastsGQL.Podcast;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	updatePodcastListens: async (id, listens) => {
		let variables = { id, listens };
		let mutation = PodcastsGQL.UpdatePodcastListens;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
	markPodcastAsPlayed: async (podcast_id, attendee_id) => {
		let variables = { podcast_id, attendee_id };
		let mutation = PodcastsGQL.MarkPodcastAsPlayed;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
	markPodcastAsUnplayed: async (podcast_id, attendee_id) => {
		let variables = { podcast_id, attendee_id };
		let mutation = PodcastsGQL.MarkPodcastAsUnplayed;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
};

export default PodcastsServices;
