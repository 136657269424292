import * as Sentry from '@sentry/react';

let Constants = {
	IS_DEVELOPMENT: false,
	CLIENT_ID: '1',
	// SSO_TEXT: 'Click Here To Test SSO Login',
	APP_NAME: 'Thālo Events',
	BPNYC_API_SERVER_URL: 'https://server.bluebirdadmin.com',
	BPNYC_API_LOCAL_SERVER_URL: 'http://localhost:8080',
	BPNYC_WS_URL: 'https://eca-websockets.bpdev.club/',
	BPNYC_WS_LOCAL_URL: 'http://localhost:4012',
	ASSETS_FOLDER: 'assets/blueprint/thalo/',
	DOMAIN: 'https://thalo.app',
	CACHE: 'never',
	SUPPORT_EMAIL: 'support@blueprintnyc.zendesk.com',
	LOGIN_TEXT: 'Admin Login',
	PASSED_EVENT: false,
	AI_PHOTOBOOTH_DOMAIN: 'https://ai-photo-booth.bpdev.club',
};
if (window.location.host.includes('localhost')) {
	Constants['AI_PHOTOBOOTH_DOMAIN'] = 'http://localhost:3009';
}

if (window.location.host.includes('staging.thalo.app')) {
	Constants['DOMAIN'] = 'https://staging.thalo.app';
	Constants['BPNYC_API_SERVER_URL'] = 'https://staging.server.bluebirdadmin.com';
	Constants['BPNYC_WS_URL'] = 'https://eca-websockets-staging.bpdev.club/';
}

export const getDomainBranding = async domain => {
	const url = window.location.origin;
	let localURL = '';
	if (process.env.NODE_ENV === 'staging' || window.location.host.includes('staging.thalo.app')) {
		console.log('IS STAGING');
		localURL = 'staging.staging'; //the backend ignores subdomains, so we have to do this
	} else if (process.env.NODE_ENV !== 'production') {
		console.log('IS LOCAL');
		//for local testing -> comment line in and switch out url
		// localURL = 'staging.staging';
		// localURL = 'thalo.app';
		// localURL = 'ucbdermnation.com';
		// localURL = 'lantheusmeetings.com';
		// localURL = 'ucbmeetings.com';
	}

	console.log('URL', localURL ? localURL : url);

	const res = await domain(localURL ? localURL : url);
	if (res.domainByURL) getClientBranding(res.domainByURL);
	else getLocalBranding();

	if (localURL) {
		getLocalBranding();
	}

	const redirectURL = localURL ? 'http://localhost:3001' : Constants['DOMAIN'];

	Constants['SSO'] = Constants['SSO_TEXT']
		? 'https://sso.bluebirdadmin.com/?client=' +
		  Constants['CLIENT_ID'] +
		  '&mobile=' +
		  encodeURIComponent(false) +
		  '&redirect=' +
		  encodeURIComponent(redirectURL + '/sso') +
		  '&referrer=' +
		  encodeURIComponent(window.location.host)
		: null;

	return Constants;
};

export const getLocalBranding = () => {
	Constants['DOMAIN'] = window.location.origin;
	Constants['BPNYC_API_SERVER_URL'] = 'http://localhost:8080';
};

export const getClientBranding = appDomain => {
	const domainBranding = JSON.parse(appDomain.branding);
	const favicon = document.getElementById('favicon');

	document.title = appDomain.app_name;
	Constants.CLIENT_ID = appDomain.client_id;

	const images = ['background', 'logo', 'nav_logo', 'favicon'];
	for (let key in domainBranding) {
		if (images.includes(key)) {
			Constants[key.toUpperCase()] = domainBranding[key].src || domainBranding[key];
			if (key === 'favicon' && domainBranding['favicon']) {
				favicon.href = domainBranding['favicon'].src || domainBranding['favicon'];
			}
		} else Constants[key.toUpperCase()] = domainBranding[key];
	}
};
console.log('CONSTANTS: ', Constants);
export default Constants;

export const configureSentry = () => {
	if (process.env.NODE_ENV === 'production') {
		Sentry.init({
			release: process.env.REACT_APP_SENTRY_RELEASE,
			dsn:
				'https://07b87d419a3aeaad83d2640484e9d14c@o4504758958358528.ingest.us.sentry.io/4509039783641088',
			integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
			// Tracing
			tracesSampleRate: 1.0, //  Capture 100% of the transactions
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ['localhost', /^https:\/\/server\.bluebirdadmin\.com/],
			// Session Replay
			replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
			replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		});
	}
};
