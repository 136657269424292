import GraphQLController from './GraphQL';
import { Constants } from '../common';
import KudosGQL from './gql/KudosGQL';

const KudosServices = {
	KudosList: async (event_id, attendee_id) => {
		let variables = {
			event_id,
			attendee_id,
			client: Constants.CLIENT_ID,
		};
		let result = await GraphQLController.Query(KudosGQL.KudosListGQL, variables);
		return result;
	},
	LikeDislikeKudos: async (kudo_id, attendee_receiver_id, attendee_giver_id, is_like) => {
		let variables = {
			kudo_id,
			attendee_receiver_id,
			attendee_giver_id,
			is_like,
		};
		let mutation = KudosGQL.LikeDislikeKudosGQL;
		let result = await GraphQLController.Mutation(mutation, variables);
		return result;
	},
};

export default KudosServices;
