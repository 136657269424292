import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PodcastDetail, PodcastsList } from 'components/Podcasts';
import { fetchPodcasts, setPinnedPodcast, setSortOrder } from 'redux/actions';

import './Podcasts.scss';

const Podcasts = props => {
	const {
		podcasts,
		theme,
		updatePlaying,
		sortOrder,
		setSortOrder,
		event,
		eventID,
		pinned,
		setPinnedPodcast,
	} = props;

	useEffect(() => {
		fetchPodcasts(eventID);
	}, []);

	useEffect(() => {
		if (podcasts && podcasts.length) setPinnedPodcast(podcasts);
	}, [podcasts]);

	return (
		<div className="podcasts-container">
			<div>{`Tune into the latest podcasts from ${event.name}!`}</div>
			{pinned && pinned.id ? (
				<div className="featured-podcast">
					<div className="featured-tag" style={{ background: theme.headerBg }}>
						Featured
					</div>
					<PodcastDetail podcast={pinned} theme={theme} updatePlaying={updatePlaying} pinned />
				</div>
			) : null}
			<PodcastsList
				podcasts={podcasts}
				theme={theme}
				updatePlaying={updatePlaying}
				setSortOrder={setSortOrder}
				sortOrder={sortOrder}
			/>
		</div>
	);
};

const msp = ({ context, events, podcasts, user }) => ({
	eventID: context.currentEventID,
	podcasts: podcasts.podcasts,
	event: events.event,
	playing: podcasts.playing.podcast,
	paused: podcasts.playing.paused,
	attendeeGroups: user.user.groups,
	upNextPlaylist: podcasts.upNextPlaylist,
	pinned: podcasts.pinned,
});

const mdp = dispatch => ({
	fetchPodcasts: async event_id => dispatch(fetchPodcasts(event_id)),
	setPinnedPodcast: async podcasts => dispatch(setPinnedPodcast(podcasts)),
	setSortOrder: async sortOrder => dispatch(setSortOrder(sortOrder)),
});

export default connect(msp, mdp)(Podcasts);
