import React from 'react';
import UpNextPreview from './UpNextPreview';
import PlayerControls from './PlayerControls';
import PlayerPreview from './PlayerPreview';
import VolumeControls from './VolumeControls/VolumeControls';

import './NowPlaying.scss';

const NowPlaying = props => {
	const {
		playing,
		theme,
		setView,
		playNextPodcast,
		volume,
		setVolume,
		playLocation,
		handleSeekerChange,
		toggleAutoplay,
		togglePausedStatus,
		paused = false,
		upNextPlaylist = [],
		autoplay,
		nextPodcast,
	} = props;

	return (
		<div className="now-playing-container">
			<div className="top">
				<PlayerPreview playing={playing} theme={theme} />
				<PlayerControls
					playing={playing}
					theme={theme}
					paused={paused}
					autoplay={autoplay}
					setView={setView}
					togglePausedStatus={togglePausedStatus}
					toggleAutoplay={toggleAutoplay}
					playLocation={playLocation}
					handleSeekerChange={handleSeekerChange}
				/>
			</div>
			<div className="bottom">
				{autoplay && upNextPlaylist.length && nextPodcast ? (
					<UpNextPreview
						nextPodcast={nextPodcast}
						playNextPodcast={playNextPodcast}
						theme={theme}
					/>
				) : null}
				<VolumeControls volume={volume} setVolume={setVolume} theme={theme} />
			</div>
		</div>
	);
};

export default NowPlaying;
