import GraphQLController from './GraphQL';
import AnnouncementsGQL from './gql/AnnouncementsGQL';

const AnnouncementsServices = {
	Announcements: async (offset, event_id) => {
		let variables = { offset: offset, event_id };
		let mutation = AnnouncementsGQL.Announcements;
		let result = await GraphQLController.Query(mutation, variables);
		return result;
	},
	SetAllAnnouncementsAsRead: async params => {
		let mutationUnread = AnnouncementsGQL.SetAllAnnouncementsAsRead;
		let mutationUnreadResult = await GraphQLController.Mutation(mutationUnread, params);
		return mutationUnreadResult;
	},
	GetUnreadAnnouncements: async params => {
		let mutationUnread = AnnouncementsGQL.GetUnreadAnnouncements;
		let mutationUnreadResult = await GraphQLController.Query(mutationUnread, params);
		return mutationUnreadResult;
	},
	GetPinnedAnnouncements: async (event_id, attendee_id) => {
		let query = AnnouncementsGQL.GetPinnedAnnouncements;
		let result = await GraphQLController.Query(query, { event_id, attendee_id });
		return result;
	},
};

export default AnnouncementsServices;
