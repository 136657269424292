import React from 'react';
import {
	// IoMdFolder,
	// IoIosPulse,
	// IoMdContacts,
	// IoMdCalendar,
	// IoMdNotifications,
	// IoIosSettings,
	// IoMdCamera,
	// IoMdImage,
	// IoIosDocument,
	IoIosGitNetwork,
	IoMdPin,
	IoIosArrowDropleftCircle,
	IoIosArrowDroprightCircle,
	IoIosKeypad,
	IoMdContact,
} from 'react-icons/io';
import {
	Home,
	Megaphone,
	Calendar,
	Contacts,
	Chats,
	Notification,
	Folder,
	Folders,
	QRCode,
	User,
	Settings,
	Page,
	Image,
	Link,
	Video,
	Camera,
	Microphone,
} from '@blueprintnyc/bpnyc-common-admin/icons';
import {
	// IoMegaphone,
	// IoHome
	IoChatboxEllipses,
	IoLogOut,
} from 'react-icons/io5';

const iconsMapping = {
	// 'IoIosDocument': IoIosDocument,
	// 'IoMdFolder': IoMdFolder,
	// 'IoIosPulse': IoIosPulse,
	// 'IoMdContacts': IoMdContacts,
	// 'IoMdCalendar': IoMdCalendar,
	// 'IoIosGitNetwork': IoIosGitNetwork,
	// 'IoMdNotifications': IoMdNotifications,
	// 'IoMdPin': IoMdPin,
	// 'IoMegaphone': IoMegaphone,
	// 'IoIosSettings': IoIosSettings,
	// 'IoHome': IoHome,
	// 'IoIosArrowDropleftCircle': IoIosArrowDropleftCircle,
	// 'IoIosArrowDroprightCircle': IoIosArrowDroprightCircle,
	// 'IoIosKeypad': IoIosKeypad,
	// 'IoMdContact': IoMdContact,
	// 'IoMdCamera': IoMdCamera,
	// 'IoMdImage': IoMdImage,
	// 'IoChatboxEllipses': IoChatboxEllipses,
	// 'IoLogOut': IoLogOut,
	'IoIosDocument': Page,
	'IoMdFolder': Folder,
	'IoIosPulse': Chats,
	'IoMdContacts': Contacts,
	'IoMdCalendar': Calendar,
	'IoIosGitNetwork': IoIosGitNetwork,
	'IoMdNotifications': Notification,
	'IoMdPin': IoMdPin,
	'IoMegaphone': Megaphone,
	'IoIosSettings': Settings,
	'IoHome': Home,
	'IoIosArrowDropleftCircle': IoIosArrowDropleftCircle,
	'IoIosArrowDroprightCircle': IoIosArrowDroprightCircle,
	'IoIosKeypad': IoIosKeypad,
	'IoMdContact': IoMdContact,
	'IoMdCamera': Camera,
	'IoMdImage': Image,
	'IoChatboxEllipses': IoChatboxEllipses,
	'IoLogOut': IoLogOut,
	'Home': Home,
	'Megaphone': Megaphone,
	'Calendar': Calendar,
	'Contacts': Contacts,
	'Chats': Chats,
	'Notification': Notification,
	'Folder': Folder,
	'Folders': Folders,
	'QRCode': QRCode,
	'User': User,
	'Settings': Settings,
	'Page': Page,
	'Image': Image,
	'Link': Link,
	'Video': Video,
	'Camera': Camera,
	'Microphone': Microphone,
};
export function CustomIcons({ icon, style }) {
	const formatIconStr = icon[0].toUpperCase() + icon.slice(1);
	const Icon = iconsMapping[formatIconStr] ?? Page;
	return <Icon style={style} />;
}
