import React from 'react';
import { connect } from 'react-redux';
import FloatingPreview from './FloatingPreview';
import FloatingControls from './FloatingControls';

import './FloatingPlayer.scss';

const FloatingPlayer = props => {
	const { playing, paused, event, togglePausedStatus, setShowSidePlayer, pathname } = props;
	const theme = JSON.parse(event.theme || '{}');

	return (
		<div className="floating-podcasts-player" style={{ background: theme.headerBg }}>
			<FloatingPreview playing={playing} />
			<FloatingControls
				playing={playing}
				paused={paused}
				theme={theme}
				togglePausedStatus={togglePausedStatus}
				setShowSidePlayer={setShowSidePlayer}
				pathname={pathname}
			/>
		</div>
	);
};

const msp = ({ events }) => ({
	event: events.event,
});

export default connect(msp, null)(FloatingPlayer);
