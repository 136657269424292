import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Popover, Badge } from 'antd';
import ProgressiveAvatar from 'components/ProgressiveAvatar';
import UserNav from 'components/UserNav';

import { thunkFetchUnreadCounts } from 'redux/actions';

import './HeaderAvatar.scss';
const HeaderAvatar = props => {
	const { user, event, getUnreadAnnouncements, unreadCount } = props;
	const { unread_app_notifications } = unreadCount;
	const isAnonLogin = event.anonymous_login === 'TRUE';
	useEffect(() => {
		(async () => {
			if (event.id && user.id) {
				await getUnreadAnnouncements(user.id, event.id, 'useEffect - HeaderAvatar');
			}
		})();
	}, [getUnreadAnnouncements, event.id, user.id]);

	if (isAnonLogin) {
		return null;
	}

	return (
		<div className="HeaderAvatar">
			<Popover content={<UserNav user={user} />} trigger="click" placement="bottomRight">
				<div className="avatar-wrapper">
					<Badge count={unread_app_notifications} size="small" offset={[-7, 7]}>
						<ProgressiveAvatar size={50} attendee={user} />
					</Badge>
				</div>
			</Popover>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.user.user,
		event: state.events.event,
		unreadCount: state.unread,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getUnreadAnnouncements: (attendeeID, eventID, from) =>
			dispatch(thunkFetchUnreadCounts(attendeeID, eventID, from)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderAvatar);
