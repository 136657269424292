import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import {
	setPlayingPodcast,
	updatePausedStatus,
	setUpNextPlaylist,
	clearPlayingPodcast,
	updatePodcastListens,
	updatePodcastAsPlayed,
	updateSidePlayerView,
} from 'redux/actions';
import { FloatingPlayer, PodcastPlayer } from 'components/Podcasts';

const PodcastPlayerRef = props => {
	const {
		podcasts = [],
		playing,
		paused,
		autoplay,
		upNextPlaylist,
		attendeeID,
		eventID,
		event,
		setPlayingPodcast,
		togglePausedStatus,
		setUpNextPlaylist,
		clearPlayingPodcast,
		updatePodcastListens,
		updatePodcastAsPlayed,
		sortOrder,
		updateSidePlayerView,
	} = props;
	const playerRef = useRef();
	const { pathname } = useLocation();
	const theme = JSON.parse(event.theme || '{}');
	const episodeIDsPlayedByAttendee = podcasts
		.filter(podcast =>
			podcast.played_by?.length && podcast.played_by?.some(attendee => attendee.id === attendeeID)
				? podcast.id
				: null
		)
		.map(podcast => podcast.id);

	const [playLocation, setPlayLocation] = useState(
		JSON.parse(localStorage.getItem('podcast'))?.playLocation || 0
	);
	const [nextPodcast, setNextPodcast] = useState({});
	const [volume, setVolume] = useState(25);
	const [view, setView] = useState('playing');
	const [showSidePlayer, setShowSidePlayer] = useState(false);

	useEffect(() => {
		const localStorageData = JSON.parse(localStorage.getItem('podcast'));
		if (eventID === localStorageData?.eventID) {
			if (localStorageData && localStorageData.playing?.id) {
				setPlayingPodcast(localStorageData.playing);
			}
			if (localStorageData && localStorageData.playLocation) {
				setPlayLocation(localStorageData.playLocation);
				handleSeekerChange(localStorageData.playLocation);
			}
		}
	}, []);

	useEffect(() => {
		togglePausedStatus(paused);
		if (!pathname.includes('podcast')) {
			updateSidePlayerView(false);
			setShowSidePlayer(false);
		}
	}, [pathname]);

	useEffect(() => {
		return () => {
			if (playing && playing.id) {
				saveToLocalStorage();
			}
		};
	}, [playLocation]);

	useEffect(() => {
		if (podcasts && podcasts.length) {
			handleSetUpNextPlaylist(playing.id, podcasts);
		}
	}, [podcasts, sortOrder]);

	useEffect(() => {
		if (upNextPlaylist.length) {
			if (upNextPlaylist[0].id !== playing.id) {
				setNextPodcast(upNextPlaylist[0]);
			} else {
				setNextPodcast(upNextPlaylist[1]);
			}
		} else setNextPodcast({});
	}, [upNextPlaylist]);

	const updatePlaying = (podcast, pausedStatus) => {
		// set playing state to podcast, set pause state to false
		if (playing.id !== podcast.id) {
			localStorage.removeItem('podcast');
			setPlayingPodcast(podcast);
			togglePausedStatus(pausedStatus || false);
			handleSetUpNextPlaylist(podcast.id);
		}
		// set pause/play podcast already set to playing
		else if (playing.id === podcast.id) {
			togglePausedStatus(!paused);
		}
	};

	const handleSetUpNextPlaylist = (id, podList) => {
		const list = podList || podcasts;
		const idx = list.findIndex(pod => pod.id === id);
		const queue = list.slice(idx + 1).filter(pod => !episodeIDsPlayedByAttendee.includes(pod.id));
		setUpNextPlaylist(queue);
	};

	const playNextPodcast = () => {
		if (autoplay && upNextPlaylist.length) {
			updatePlaying(nextPodcast);
		} else togglePausedStatus(true);
	};

	const handleSongEnded = () => {
		localStorage.removeItem('podcast');
		updatePodcastListens(playing.id, playing.listens + 1);
		if (!episodeIDsPlayedByAttendee.includes(playing.id)) {
			updatePodcastAsPlayed(playing.id, attendeeID);
		}
		togglePausedStatus(true);
		if (!nextPodcast.id) {
			clearPlayingPodcast();
			localStorage.removeItem('podcast');
			if (setShowSidePlayer) setShowSidePlayer(false);
		} else setTimeout(() => playNextPodcast(), 1000);
	};

	const setProgress = playedSeconds => {
		if (playLocation >= playing.duration) {
			// song ended
			setPlayLocation(0);
			playerRef.current.seekTo(0, 'seconds');
		} else if (playedSeconds === 0 && playLocation !== 0) {
			// restart song at last played location
			playerRef.current.seekTo(playLocation, 'seconds');
		} else {
			// update play location
			setPlayLocation(playedSeconds);
		}
	};

	const handleSeekerChange = time => {
		const setTime = time < 0 ? 0 : time > playing.duration ? playing.duration : time;
		setPlayLocation(setTime);
		playerRef.current.seekTo(setTime, 'seconds');
	};

	const saveToLocalStorage = () =>
		localStorage.setItem('podcast', JSON.stringify({ playLocation, playing, eventID }));

	return (
		<div className="podcast-player-ref">
			<ReactPlayer
				ref={playerRef}
				style={{ display: 'none' }}
				url={playing.media_src}
				playing={!paused}
				volume={volume / 100}
				onProgress={({ playedSeconds }) => setProgress(playedSeconds)}
				onSeek={setPlayLocation}
				onEnded={handleSongEnded}
			/>
			{playing?.id && (!showSidePlayer || !pathname.includes('podcast')) ? (
				<FloatingPlayer
					setNextPodcast={setNextPodcast}
					episodeIDsPlayedByAttendee={episodeIDsPlayedByAttendee}
					playing={playing}
					paused={paused}
					togglePausedStatus={togglePausedStatus}
					setShowSidePlayer={setShowSidePlayer}
					pathname={pathname}
				/>
			) : null}
			{showSidePlayer && pathname.includes('podcast') ? (
				<PodcastPlayer
					playing={playing}
					theme={theme}
					updatePlaying={updatePlaying}
					view={view}
					setView={setView}
					playNextPodcast={playNextPodcast}
					setShowSidePlayer={setShowSidePlayer}
					volume={volume}
					setVolume={setVolume}
					playLocation={playLocation}
					handleSeekerChange={handleSeekerChange}
					nextPodcast={nextPodcast}
				/>
			) : null}
		</div>
	);
};

const msp = ({ events, user, podcasts }) => ({
	podcasts: podcasts.podcasts,
	playing: podcasts.playing.podcast,
	paused: podcasts.playing.paused,
	autoplay: podcasts.autoplay,
	upNextPlaylist: podcasts.upNextPlaylist,
	attendeeID: user.user.id,
	eventID: events.event.id,
	event: events.event,
	sortOrder: podcasts.sortOrder,
});

const mdp = dispatch => ({
	setPlayingPodcast: async podcast => dispatch(setPlayingPodcast(podcast)),
	togglePausedStatus: paused => dispatch(updatePausedStatus(paused)),
	setUpNextPlaylist: async podcasts => dispatch(setUpNextPlaylist(podcasts)),
	clearPlayingPodcast: async () => dispatch(clearPlayingPodcast()),
	updatePodcastListens: async (podcast_id, listens) =>
		dispatch(updatePodcastListens(podcast_id, listens)),
	updatePodcastAsPlayed: async (podcastID, attendeeID) =>
		dispatch(updatePodcastAsPlayed(podcastID, attendeeID)),
	updateSidePlayerView: async view => dispatch(updateSidePlayerView(view)),
});

export default connect(msp, mdp)(PodcastPlayerRef);
