import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PodcastActionsMenu from '../PodcastActionsMenu';
import { Check, Play, Pause, Replay } from '@blueprintnyc/bpnyc-common-admin/icons';
import { formatDate, formatSimplifiedTime } from 'common';

import './PodcastMediaPreview.scss';

const circleDivider = <div className="circle-divider" />;

const PodcastMediaPreview = props => {
	const {
		podcast,
		theme,
		type,
		playing,
		paused,
		attendeeID,
		updatePlaying,
		showSidePlayer,
	} = props;
	const isVideo = podcast.mime_type.includes('video');
	const playedByAttendee = podcast.played_by.filter(attendee => attendee.id === attendeeID);
	const playIcon = playedByAttendee.length ? <Replay /> : <Play />;

	const handleUpdatePlaying = () => updatePlaying(podcast);

	return (
		podcast && (
			<div className={`podcast-media-preview ${type} ${showSidePlayer ? 'side-player-open' : ''}`}>
				<Link className="art-container" to={`/podcasts/${podcast.id}`}>
					<img src={podcast.art_src} alt="podcast-episode-art" className="podcast-art" />
				</Link>
				<div className="content-container">
					<div>
						<div className="podcast-date-type-duration">
							<div
								className="check-icon"
								style={{
									display: playedByAttendee.length ? '' : 'none',
								}}
							>
								{playedByAttendee.length ? <Check fontSize={12} /> : null}
							</div>
							<div className="date">{formatDate(podcast.created_at)}</div>
							{isVideo ? (
								<>
									{circleDivider} <div className="type">VIDEO</div>
								</>
							) : null}
						</div>
						<div className="podcast-title">{podcast.name}</div>
						<div className="podcast-description">{podcast.description}</div>
					</div>
					<div className="podcast-actions">
						<div
							className="play-pause-btn"
							style={{ background: type === 'up-next' ? theme.brandPrimary : theme.headerBg }}
							onClick={handleUpdatePlaying}
						>
							{playing.id === podcast.id && !paused ? <Pause /> : playIcon}{' '}
							{formatSimplifiedTime(podcast.duration)}
						</div>
						<PodcastActionsMenu
							podcast={podcast}
							attendeeID={attendeeID}
							playedByAttendee={playedByAttendee}
						/>
					</div>
				</div>
			</div>
		)
	);
};

const msp = ({ podcasts, user }) => ({
	playing: podcasts.playing.podcast,
	paused: podcasts.playing.paused,
	attendeeID: user.user.id,
	showSidePlayer: podcasts.sidePlayerView,
});

export default connect(msp, null)(PodcastMediaPreview);
