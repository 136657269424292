// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { Constants } from './common';
import * as serviceWorker from './serviceWorker';
import { configureSentry } from './AppConfig';

import { initializeFirebase } from './push-notification';
// import registerServiceWorker from './registerServiceWorker';

const { CACHE } = Constants;

configureSentry();

ReactDOM.render(<App />, document.getElementById('root'));

// This is just for cache problems
// If you find problems with push notifications, look here
// registerServiceWorker();
initializeFirebase();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if (CACHE === 'never') {
	serviceWorker.unregister();
} else {
	serviceWorker.register();
}
