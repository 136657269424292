import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { isMobile, isJson } from '../../common';
import Sidebar from '../Sidebar';
import AppHeader from '../AppHeader';
import { fetchEvent } from 'redux/actions';

import 'antd/dist/antd.min.css';
import './index.scss';

const { Sider } = Layout;

class Index extends React.PureComponent {
	state = {
		collapsed: false,
	};

	_isMounted = false;

	componentWillUnmount() {
		this._isMounted = false;
	}
	onCollapse = collapsed => {
		console.log(collapsed);
		this.setState(prevState => ({ collapsed: !prevState.collapsed }));
	};

	componentDidMount() {
		this._isMounted = true;
		window.addEventListener('resize', this.resize.bind(this));
		this.resize();
	}

	resize() {
		let isMobileDevice = isMobile();
		if (this._isMounted) {
			this.setState({ hideNav: isMobileDevice });
		}
	}
	renderInnerLayout = () => {
		const { domainBranding, event, backgroundImage, backgroundColor } = this.props;
		const { ASSETS_FOLDER, BACKGROUND, APP_NAME } = domainBranding;
		const { active_homescreen, homescreen_theme } = event;

		let backgroundUri;
		let backgroundcolor;
		let containerBgColor = '#f6f6f6';
		if (isJson(event.theme) !== event.theme) {
			var { headerBg, headerText } = isJson(event.theme);
		}
		const containerImage = backgroundImage ? backgroundImage : '';
		containerBgColor = backgroundColor ? backgroundColor : containerBgColor;

		if (active_homescreen === 'WIDGET') {
			// widget homescreen
			const { background_color: color, background_src: src } = isJson(homescreen_theme);
			if (color) backgroundcolor = color;
			if (src) backgroundUri = src;
		} else {
			// standard homescreen
			const layout = isJson(event.homescreen_layout);
			if (layout && layout.background) {
				backgroundUri =
					typeof layout.background.source.uri !== 'undefined'
						? layout.background.source.uri
						: BACKGROUND
						? BACKGROUND
						: ASSETS_FOLDER + 'background.png';
				backgroundcolor =
					typeof layout.background.color !== 'undefined'
						? layout.background.color
						: layout.background.source.color
						? layout.background.source.color
						: '#02203f';
			}
		}

		const homeScreenStyle = {
			backgroundSize: 'cover',
			backgroundColor: backgroundcolor,
			backgroundImage: `url(${backgroundUri})`,
		};
		const style = {
			backgroundSize: 'cover',
			backgroundColor: containerBgColor,
			backgroundImage: `url(${containerImage})`,
		};

		let homeScreenStyleCheck = false;
		if (this.props.children && !Array.isArray(this.props.children)) {
			this.props.children.props.className === 'homeScreen'
				? (homeScreenStyleCheck = true)
				: (homeScreenStyleCheck = false);
		}
		return (
			<div id="innerLayoutContainer">
				{!this.state.hideNav && event.side_navigation !== '{}' && APP_NAME !== 'Team USA Tour' && (
					<Sider
						style={{
							backgroundColor: headerBg,
							color: headerText,
						}}
						theme={'dark'}
						collapsed={this.state.collapsed}
						width={'220px'}
					>
						<Sidebar
							{...this.props}
							collapsed={this.state.collapsed}
							onCollapse={this.onCollapse}
						/>
					</Sider>
				)}
				<div id="renderChildrenContainer" style={homeScreenStyleCheck ? homeScreenStyle : style}>
					{this.props.children}
				</div>
			</div>
		);
	};

	render() {
		window.event = this.props;
		// console.log('🚨   ICON', this.props.icon, this.props);
		return (
			<div id="layoutsContainer">
				<AppHeader
					icon={this.props.icon}
					title={this.props.title}
					back={this.props.back}
					hideNav={this.state.hideNav}
					backButtonPressed={this.props.backButtonPressed}
					{...this.props}
				/>
				{this.renderInnerLayout()}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		event: state.events.event,
		domainBranding: state.domains.domainBranding,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		applyevent: eventId => dispatch(fetchEvent(eventId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
