import React from 'react';
import { Slider, Switch } from 'antd';
import { Play, Pause, FastForward, List, Rewind } from '@blueprintnyc/bpnyc-common-admin/icons';
import { formatTime } from 'common';

import './PlayerControls.scss';

const PlayerControls = props => {
	const {
		playing,
		theme,
		paused,
		autoplay,
		setView,
		togglePausedStatus,
		toggleAutoplay,
		playLocation,
		handleSeekerChange,
	} = props;

	const handlePlayPause = () => togglePausedStatus(!paused);

	return (
		<div className="player-controls-container" style={{ color: theme.headerText }}>
			<div className="autoplay-container">
				<div className="autoplay-toggle">
					<div className="autoplay-label">Autoplay</div>
					<Switch
						default={autoplay}
						checkedChildren="On"
						unCheckedChildren="Off"
						defaultChecked={autoplay}
						onChange={toggleAutoplay}
					/>
				</div>
				{autoplay ? <List onClick={() => setView('up-next')} /> : null}
			</div>
			<div className="play-controls">
				<Slider
					className="seek-slider"
					onChange={handleSeekerChange}
					value={playLocation}
					min={0}
					max={playing.duration}
				/>
				<div className="duration">
					<span id="current-time" className="time">
						{formatTime(playLocation)}
					</span>
					<span id="duration" className="time">
						{formatTime(playing.duration)}
					</span>
				</div>
			</div>
			<div className="play-btns">
				<div className="icon" onClick={() => handleSeekerChange(playLocation - 10)}>
					<Rewind />
				</div>
				<div
					className="play-pause-btn"
					id="play-pause-btn"
					onClick={handlePlayPause}
					style={{ background: theme.brandPrimary }}
				>
					{paused ? <Play style={{ marginLeft: 5 }} /> : <Pause />}
				</div>
				<div className="icon" onClick={() => handleSeekerChange(playLocation + 10)}>
					<FastForward />
				</div>
			</div>
		</div>
	);
};

export default PlayerControls;
