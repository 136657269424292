import { gql } from 'graphql-tag';

const CheckInsGQL = {
	CheckIns: gql`
		query checkIns($event_id: Uint!, $attendee_id: Uint!) {
			checkIns(event_id: $event_id, attendee_id: $attendee_id) {
				nodes {
					id
					event_id
					location {
						id
						name
						description
						attendee_description
						image_url
						button_text
						link
					}
					created_at
					updated_at
				}
			}
		}
	`,
};

export default CheckInsGQL;
