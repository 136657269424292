import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { graphQL_EndPoint } from './APIEndPoints';
import { store } from '../store/store.js';

const setHeader = async (operation, token) => {
	// console.log('ApolloClient TOKEN ', token, operation.operationName);
	operation.setContext({
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const ac = new ApolloClient({
	link: ApolloLink.from([
		onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.forEach(({ message, locations, path }) =>
					console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
				);
			if (networkError) console.log(`[Network error]: ${networkError}`);
		}),
		new ApolloLink((operation, forward) => {
			const token = store.getState().user.token;
			//The login and domainByURL operations do not require a token, but our server will throw an error if we send a request
			//without a token. Therefore, we can allow these operations to proceed without a token.
			if (
				token ||
				operation.operationName === 'login' ||
				operation.operationName === 'register' ||
				operation.operationName === 'domainByURL' ||
				operation.operationName === 'leaderboardSettings' ||
				operation.operationName === 'eventTheme' ||
				typeof jest !== 'undefined'
			) {
				setHeader(operation, token);
				return forward(operation);
			} else {
				console.log('Error no token for: ', operation.operationName);
			}
		}),
		new HttpLink({
			uri: graphQL_EndPoint,
			credentials: 'same-origin',
		}),
	]),
	// request: operation => setHeader(operation),
	cache: new InMemoryCache(),
});

export default ac;
