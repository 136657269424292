import { gql } from 'graphql-tag';

const AgendaGQL = {
	Agenda: gql`
		query agenda($event_id: Uint, $track_id: Uint) {
			agenda(event_id: $event_id, track_id: $track_id) {
				id
				name
				location
				image_url
				link
				agenda_color
				description
				notes
				start_time
				end_time
				recurring
				recurring_type
				updated_at
				materials {
					id
					name
					link
					groups {
						id
						name
						track
						event_id
					}
				}
			}
		}
	`,
};

export default AgendaGQL;
