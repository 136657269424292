import { PodcastsServices } from '../../services';

export const SET_PODCASTS = 'SET_PODCASTS';
export const SET_PODCAST = 'SET_PODCAST';
export const UPDATE_PODCAST_LISTENS = 'UPDATE_PODCAST_LISTENS';
export const CLEAR_PODCASTS = 'CLEAR_PODCASTS';
export const CLEAR_PODCAST = 'CLEAR_PODCAST';
export const UPDATE_AUTOPLAY = 'UPDATE_AUTOPLAY';
export const SET_PLAYING_PODCAST = 'SET_PLAYING_PODCAST';
export const UPDATE_PAUSED_STATUS = 'UPDATE_PAUSED_STATUS';
export const SET_UP_NEXT_PLAYLIST = 'SET_UP_NEXT_PLAYLIST';
export const UPDATE_PODCAST = 'UPDATE_PODCAST';
export const ADD_NEW_PODCAST = 'ADD_NEW_PODCAST';
export const DELETE_PODCAST = 'DELETE_PODCAST';
export const CLEAR_PLAYING_PODCAST = 'CLEAR_PLAYING_PODCAST';
export const SET_PINNED_PODCAST = 'SET_PINNED_PODCAST,';
export const SET_SORT_ORDER = 'SET_SORT_ORDER';
export const UPDATE_PLAYED_PODCAST_EPISODES = 'UPDATE_PLAYED_PODCAST_EPISODES';
export const UPDATE_PODCASTS_ROUTE_AS_VIEWED = 'UPDATE_PODCASTS_ROUTE_AS_VIEWED';
export const UPDATE_SIDE_PLAYER_VIEW = 'UPDATE_SIDE_PLAYER_VIEW';

//setters
export const setPodcasts = podcasts => ({
	type: SET_PODCASTS,
	podcasts,
});

export const setPodcast = podcast => ({
	type: SET_PODCAST,
	podcast,
});

export const setPodcastListens = podcast => ({
	type: UPDATE_PODCAST_LISTENS,
	podcast,
});

export const clearPodcasts = () => ({
	type: CLEAR_PODCASTS,
});

export const clearPodcast = () => ({
	type: CLEAR_PODCAST,
});

export const updateAutoplay = autoplay => ({
	type: UPDATE_AUTOPLAY,
	autoplay,
});

export const setPlayingPodcast = podcast => ({
	type: SET_PLAYING_PODCAST,
	podcast,
});

export const updatePausedStatus = pauseStatus => ({
	type: UPDATE_PAUSED_STATUS,
	pauseStatus,
});

export const setUpNextPlaylist = podcasts => ({
	type: SET_UP_NEXT_PLAYLIST,
	podcasts,
});

export const updatePodcast = (podcast, attendeeGroupIDs) => ({
	type: UPDATE_PODCAST,
	podcast,
	attendeeGroupIDs,
});

export const addNewPodcast = podcast => ({
	type: ADD_NEW_PODCAST,
	podcast,
});

export const deletePodcast = ({ deletePodcast }) => ({
	type: DELETE_PODCAST,
	podcastID: deletePodcast.id,
});

export const clearPlayingPodcast = () => ({
	type: CLEAR_PLAYING_PODCAST,
});

export const setPinnedPodcast = podcasts => ({
	type: SET_PINNED_PODCAST,
	podcasts,
});

export const setSortOrder = (sortOrder, attendeeGroupIDs) => ({
	type: SET_SORT_ORDER,
	sortOrder,
	attendeeGroupIDs,
});

export const updatePlayedPodcastEpisodes = podcast => ({
	type: UPDATE_PLAYED_PODCAST_EPISODES,
	podcast,
});

export const updatePodcastsRouteAsViewed = viewed => ({
	type: UPDATE_PODCASTS_ROUTE_AS_VIEWED,
	viewed: viewed,
});

export const updateSidePlayerView = sidePlayerView => ({
	type: UPDATE_SIDE_PLAYER_VIEW,
	sidePlayerView,
});

//getters
export const fetchPodcasts = (event_id, attendeeGroupIDs) => async dispatch => {
	const { error, data } = await PodcastsServices.podcasts(event_id);
	if (data) {
		dispatch(setPodcasts(data.podcasts, attendeeGroupIDs));
		return data;
	} else return error.message;
};

export const fetchPodcast = podcastID => async dispatch => {
	const { error, data } = await PodcastsServices.podcast(podcastID);
	if (data) {
		dispatch(setPodcast(data.podcast));
		return data;
	} else return error.message;
};

export const updatePodcastListens = (podcastID, listens) => async dispatch => {
	const { error, data } = await PodcastsServices.updatePodcastListens(podcastID, listens);
	if (data) {
		dispatch(setPodcastListens(data.updatePodcastListens));
		return data;
	} else return error.message;
};

export const updatePodcastAsPlayed = (podcastID, attendeeID) => async dispatch => {
	const { error, data } = await PodcastsServices.markPodcastAsPlayed(podcastID, attendeeID);
	if (data) {
		dispatch(updatePlayedPodcastEpisodes(data.markPodcastAsPlayed));
		return data;
	} else return error.message;
};

export const removePodcastAsPlayed = (podcastID, attendeeID) => async dispatch => {
	const { error, data } = await PodcastsServices.markPodcastAsUnplayed(podcastID, attendeeID);
	if (data) {
		dispatch(updatePlayedPodcastEpisodes(data.markPodcastAsUnplayed));
		return data;
	} else return error.message;
};
