import React from 'react';
import SubscriptionTemplate from './SubscriptionTemplate';
import { connect } from 'react-redux';
import {
	setEvent,
	addGroup,
	updateGroup,
	deleteGroup,
	addPage,
	updatePage,
	deletePage,
	fetchEventWidgets,
} from 'redux/actions';

const WS_EVENTS_BY_HTTP = {
	//Event Updates
	UPDATE_EVENT: 'event/:eventID/PATCH',
	UPDATE_ATTENDEE_METADATA: 'attendee/metadata/:attendeeID/PATCH',
	CREATE_GROUP: 'groups/POST',
	UPDATE_GROUP: 'groups/:groupID/PATCH',
	DUPLICATE_GROUP: 'groups/:groupID/duplicate/PATCH',
	DELETE_GROUP: 'groups/:groupID/DELETE',
	CREATE_PAGE: 'event/:eventID/pages/POST',
	UPDATE_PAGE: 'event/:eventID/pages/:pageID/PATCH',
	DELETE_PAGE: 'event/:eventID/pages/:pageID/DELETE',
};

const WebAppSubscriptions = props => {
	const {
		socket,
		eventID,
		setEventData,
		addGroup,
		updateGroup,
		deleteGroup,
		addPage,
		updatePage,
		deletePage,
		updateWidgets,
	} = props;
	const propsToSend = { ...props };
	delete propsToSend.children;

	const subscribe = () => {
		console.log('⚡️ WebAppSubscriptions Mounted ⚡️');
		socket.emit('app/event/:eventID/SUBSCRIBE', { eventID });
	};

	const unsubscribe = prevEventID => {
		console.log(`🚶‍♂️ WebAppSubscriptions Unmounted 🚶‍♂️`);
		socket.emit('app/event/:eventID/UNSUBSCRIBE', { eventID: prevEventID || eventID });
	};

	const listenForUpdatedEvent = () => {
		socket.on(WS_EVENTS_BY_HTTP.UPDATE_EVENT, payload => {
			const { data, error, from } = payload;
			if (error) console.log('Error updating page', error);
			else {
				setEventData(data, 'listenForUpdatedEvent');
				if (from === 'widgetHomescreen') updateWidgets(data.id, 'listenForUpdatedEvent');
				console.log('This event has been updated!');
			}
		});
	};

	const listenForUpdatedAttendeeMetadata = () => {
		socket.on(WS_EVENTS_BY_HTTP.UPDATE_ATTENDEE_METADATA, payload => {
			const { data, error } = payload;
			if (error) console.log('Error updating attendee metadata', error);
			else {
				if (data.event_id === eventID)
					setEventData(
						{ attendee_metadata: data, id: data.event_id, updateMetadata: true },
						'listenForUpdatedAttendeeMetadata'
					);
				console.log('This attendee metadata has been updated!');
			}
		});
	};

	//GROUPS
	const listenForAddedEventGroup = () => {
		socket.on(WS_EVENTS_BY_HTTP.CREATE_GROUP, payload => {
			const { data, error } = payload;

			if (error) console.log('Error creating group', error);
			else {
				const { eventID } = payload;
				addGroup({ data, eventID }, 'App Subscription - Add Group');
				console.log('A new group has been added');
			}
		});
	};

	const listenForDuplicatedEventGroup = () => {
		socket.on(WS_EVENTS_BY_HTTP.DUPLICATE_GROUP, payload => {
			const { data, error } = payload;
			if (error) console.log('Error duplicating group', error);
			else {
				const { eventID } = payload;
				delete data.attendees;
				addGroup({ data, eventID }, 'App Subscription - Duplicated Group');
				console.log('A group has been duplicated');
			}
		});
	};

	const listenForUpdatedEventGroup = () => {
		socket.on(WS_EVENTS_BY_HTTP.UPDATE_GROUP, payload => {
			const { data, error } = payload;
			if (error) console.log('Error updating group', error);
			else {
				const { eventID } = payload;
				updateGroup({ data, eventID }, 'App Subscription - Update Group');
				console.log('A group has been updated');
			}
		});
	};

	const listenForDeletedEventGroup = () => {
		socket.on(WS_EVENTS_BY_HTTP.DELETE_GROUP, payload => {
			const { data, error } = payload;
			if (error) console.log('Error deleting group', error);
			else {
				const { deleteGroup: group } = data;
				const { eventID } = payload;

				deleteGroup({ groupID: group.id, eventID }, 'App Subscription - Delete Group');
				console.log('A group has been deleted');
			}
		});
	};

	// //PAGES
	const listenForAddedPage = () => {
		socket.on(WS_EVENTS_BY_HTTP.CREATE_PAGE, payload => {
			const { data, error } = payload;
			if (error) console.log('Error creating page', error);
			else {
				const { eventID } = payload;
				addPage({ data, eventID }, 'App Subscription - Add Page');
				console.log('A new page has been added');
			}
		});
	};

	const listenForUpdatedPage = () => {
		socket.on(WS_EVENTS_BY_HTTP.UPDATE_PAGE, payload => {
			const { data, error } = payload;
			if (error) console.log('Error updating page', error);
			else {
				const { eventID } = payload;
				updatePage({ data, eventID }, 'App Subscription - Update Group');
				console.log('A page has been updated');
			}
		});
	};

	const listenForDeletedPage = () => {
		socket.on(WS_EVENTS_BY_HTTP.DELETE_PAGE, payload => {
			const { data, error } = payload;
			if (error) console.log('Error deleting page', error);
			else {
				const { eventID } = payload;
				deletePage({ pageID: data.id, eventID }, 'App Subscription - Delete Page');
				console.log('A page has been deleted');
			}
		});
	};

	const listeners = () => [
		listenForUpdatedEvent,
		listenForUpdatedAttendeeMetadata,
		listenForDuplicatedEventGroup,
		listenForAddedEventGroup,
		listenForUpdatedEventGroup,
		listenForDeletedEventGroup,
		listenForAddedPage,
		listenForUpdatedPage,
		listenForDeletedPage,
	];

	return (
		<>
			<SubscriptionTemplate
				{...props}
				sub={subscribe}
				unsub={prevEventID => unsubscribe(prevEventID)}
				listeners={listeners()}
				routes={Object.values(WS_EVENTS_BY_HTTP)}
				from={'event'}
			/>
			{React.cloneElement(props.children, propsToSend)}
		</>
	);
};

const msp = ({ ws, context }) => ({
	socket: ws.socket,
	eventID: context.currentEventID,
});

const mdp = dispatch => ({
	setEventData: (data, from) => dispatch(setEvent(data, from)),
	addGroup: (group, from) => dispatch(addGroup(group, from)),
	updateGroup: (group, from) => dispatch(updateGroup(group, from)),
	deleteGroup: (group, from) => dispatch(deleteGroup(group, from)),
	addPage: (page, from) => dispatch(addPage(page, from)),
	updatePage: (page, from) => dispatch(updatePage(page, from)),
	deletePage: (page, from) => dispatch(deletePage(page, from)),
	updateWidgets: (eventID, from) => dispatch(fetchEventWidgets(eventID, from)),
});

export default connect(msp, mdp)(WebAppSubscriptions);
