import React from 'react';
import { Skip } from '@blueprintnyc/bpnyc-common-admin/icons';

import './UpNextPreview.scss';

const UpNextPreview = props => {
	const { nextPodcast, playNextPodcast, theme } = props;

	return (
		<div className="up-next-preview-container" style={{ color: theme.headerText }}>
			<div className="section-header">Up Next</div>
			<div className="up-next-preview">
				<div className="left">
					<img className="cover-art" src={nextPodcast.art_src} alt="podcast-cover-art" />
					<div className="podcast-title">{nextPodcast.name}</div>
				</div>
				<Skip onClick={playNextPodcast} />
			</div>
		</div>
	);
};

export default UpNextPreview;
