import { LeaderboardServices } from '../../services';

export const SET_LEADERBOARD = 'SET_LEADERBOARD';
export const SET_LEADERBOARD_ATTENDEE_RECORD = 'SET_LEADERBOARD_ATTENDEE_RECORD';
export const SET_LEADERBOARD_REFETCH = 'SET_LEADERBOARD_REFETCH';

// Setters
export const setLeaderboard = (leaderboard, from) => ({
	type: SET_LEADERBOARD,
	leaderboard,
	from,
});

export const setLeaderboardAttendeeRecord = (leaderboardAttendeeRecord, from) => ({
	type: SET_LEADERBOARD_ATTENDEE_RECORD,
	leaderboardAttendeeRecord,
	from,
});

export const setLeaderboardRefetch = from => ({
	type: SET_LEADERBOARD_REFETCH,
	from,
});

// Getters
export const getLeaderboard = (leaderboardID, from) => async dispatch => {
	let { error, data } = await LeaderboardServices.leaderboardService(leaderboardID);
	if (data) {
		const { leaderboardSettings } = data;
		dispatch(setLeaderboard(leaderboardSettings, from));
	} else return error.message;
};

export const getAttendeeLeaderboard = (leaderboardID, attendeeID, from) => async dispatch => {
	let { error, data } = await LeaderboardServices.leaderboardAttendeeService(
		leaderboardID,
		attendeeID
	);
	if (data) {
		const { leaderboardAttendee } = data;
		dispatch(setLeaderboardAttendeeRecord(leaderboardAttendee, from));
	} else return error.message;
};
