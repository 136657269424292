import { gql } from 'graphql-tag';

const NotificationsGQL = {
	AttendeeNotifications: gql`
		query attendeeNotifications($attendee_id: Uint, $event_id: Uint) {
			attendeeNotifications(attendee_id: $attendee_id, event_id: $event_id) {
				id
				attendee_receiver_id
				attendee_giver_id
				attendee_giver {
					id
					email
					first_name
					last_name
					avatar_url
				}
				link
				action
				body
				read
				event_id
				item_id
				created_at
			}
		}
	`,

	UnreadNotificationsAndAnnouncementsCounts: gql`
		query unreadNotificationsAndAnnouncementsCounts($attendee_id: Uint!, $event_id: Uint!) {
			unreadNotificationsAndAnnouncementsCounts(attendee_id: $attendee_id, event_id: $event_id) {
				unread_announcements
				unread_app_notifications
			}
		}
	`,

	SetAllNotificationsAsRead: gql`
		mutation setAllNotificationsAsRead($attendee_id: Uint!, $event_id: Uint!) {
			setAllNotificationsAsRead(attendee_id: $attendee_id, event_id: $event_id) {
				id
				read
			}
		}
	`,
};

export default NotificationsGQL;
