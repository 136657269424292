import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Play, Pause, Expand, Menu, CloseCircle } from '@blueprintnyc/bpnyc-common-admin/icons';
import { updateSidePlayerView, updatePodcastsRouteAsViewed } from 'redux/actions';
import { Popover } from 'antd';

import './FloatingControls.scss';

const FloatingControls = props => {
	const {
		paused,
		theme,
		togglePausedStatus,
		setShowSidePlayer,
		pathname,
		updateSidePlayerView,
		updatePodcastsRouteAsViewed,
	} = props;
	const [menuOpen, setMenuOpen] = useState(false);

	const handlePlayPause = () => togglePausedStatus(!paused);

	const toggleSidePlayerView = () => {
		updateSidePlayerView(true);
		setShowSidePlayer(true);
	};

	const handleDismissFloatingPlayer = () => {
		togglePausedStatus(true);
		updatePodcastsRouteAsViewed(false);
	};

	const floatingMenuDiv = (
		<div className="floating-action-menu">
			{pathname.includes('podcast') ? (
				<div className="floating-action-opt" onClick={toggleSidePlayerView}>
					<Expand />
					<div>Expand</div>
				</div>
			) : null}
			<div className="floating-action-opt" onClick={handleDismissFloatingPlayer}>
				<CloseCircle />
				<div>Dismiss</div>
			</div>
		</div>
	);

	return (
		<div className="floating-controls-pill">
			<div
				className="play-pause-btn"
				onClick={handlePlayPause}
				style={{ background: theme.brandPrimary }}
			>
				{paused ? <Play style={{ marginLeft: 2 }} /> : <Pause />}
			</div>
			<Popover
				content={floatingMenuDiv}
				trigger="click"
				placement="top"
				open={menuOpen}
				onOpenChange={isOpen => {
					if (isOpen) {
						return;
					}
					setMenuOpen(false);
				}}
			>
				<Menu fontSize={22} className="menu-btn" onClick={() => setMenuOpen(true)} />
			</Popover>
		</div>
	);
};

const mdp = dispatch => ({
	updateSidePlayerView: async view => dispatch(updateSidePlayerView(view)),
	updatePodcastsRouteAsViewed: async view => dispatch(updatePodcastsRouteAsViewed(view)),
});

export default connect(null, mdp)(FloatingControls);
