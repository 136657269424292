import {
	SET_PODCASTS,
	SET_PODCAST,
	UPDATE_PODCAST_LISTENS,
	CLEAR_PODCASTS,
	CLEAR_PODCAST,
	UPDATE_AUTOPLAY,
	SET_PLAYING_PODCAST,
	UPDATE_PAUSED_STATUS,
	SET_UP_NEXT_PLAYLIST,
	UPDATE_PODCAST,
	ADD_NEW_PODCAST,
	DELETE_PODCAST,
	CLEAR_PLAYING_PODCAST,
	SET_PINNED_PODCAST,
	SET_SORT_ORDER,
	UPDATE_PLAYED_PODCAST_EPISODES,
	UPDATE_PODCASTS_ROUTE_AS_VIEWED,
	UPDATE_SIDE_PLAYER_VIEW,
} from 'redux/actions';

const initialState = {
	podcasts: [],
	podcast: {},
	pinned: {},
	playing: {
		podcast: {},
		paused: true,
		pauseTime: 0,
	},
	upNextPlaylist: [],
	autoplay: true,
	sortOrder: 'dec',
	podcastsRouteViewed: false,
	sidePlayerView: false,
};

const updatePodcasts = (podcasts, podcast) => {
	const idx = podcasts.find(pod => pod.id === podcast.id);
	if (!idx) return [...podcasts, podcast];
	else return podcasts.map(pod => (pod.id === podcast.id ? podcast : pod));
};

const deletePodcast = (podcasts, podcastID) => podcasts.filter(pod => pod.id !== podcastID);

const findPinnedPodcast = (podcasts, sortOrder) => {
	const pinnedArr = podcasts.filter(pod => !pod.hidden && pod.pinned);
	if (pinnedArr.length) return pinnedArr[0];
	else return sortOrder === 'dec' ? podcasts[0] : podcasts[podcasts.length - 1];
};

const sortPodcasts = (podcasts, sortOrder, attendeeGroupIDs) =>
	podcasts
		.filter(
			pod =>
				!pod.hidden &&
				(pod.groups.length === 0 ||
					(attendeeGroupIDs && pod.groups.some(g => attendeeGroupIDs.includes(g.id))))
		)
		.sort((a, b) =>
			sortOrder === 'asc'
				? new Date(a.created_at) - new Date(b.created_at)
				: new Date(b.created_at) - new Date(a.created_at)
		);

const updateUpNextPlaylist = (podcasts, playing, autoplay) => {
	const index = podcasts.findIndex(pod => pod.id === playing.id);
	return autoplay ? podcasts.slice(index + 1) : [];
};

const podcastsReducer = (state = initialState, action) => {
	const {
		type,
		podcasts,
		podcast,
		autoplay,
		pauseStatus,
		podcastID,
		attendeeGroupIDs,
		sortOrder,
		sidePlayerView,
		viewed,
	} = action;
	switch (type) {
		case SET_PODCASTS:
			const sortedPods = sortPodcasts(podcasts, state.sortOrder, attendeeGroupIDs);
			return { ...state, podcasts: sortedPods };
		case SET_PODCAST:
			return { ...state, podcast };
		case UPDATE_PODCAST_LISTENS:
			const updatedPodListens = updatePodcasts(state.podcasts, podcast);
			return {
				...state,
				podcasts: updatedPodListens,
				playing: { ...state.playing, podcast: state.upNextPlaylist.length ? podcast : {} },
			};
		case CLEAR_PODCASTS:
			return { ...initialState };
		case CLEAR_PODCAST:
			return { ...state, podcast: {} };
		case UPDATE_AUTOPLAY:
			const playlist = updateUpNextPlaylist(state.podcasts, state.playing.podcast, autoplay);
			return { ...state, autoplay, upNextPlaylist: playlist };
		case SET_PLAYING_PODCAST:
			return { ...state, playing: { ...state.playing, podcast, paused: false } };
		case UPDATE_PAUSED_STATUS:
			return { ...state, playing: { ...state.playing, paused: pauseStatus } };
		case SET_UP_NEXT_PLAYLIST:
			return { ...state, upNextPlaylist: podcasts };
		case UPDATE_PODCAST:
			const podcastsAfterUpdate = updatePodcasts(state.podcasts, podcast);
			const sortedUpdatedPods = sortPodcasts(
				podcastsAfterUpdate,
				state.sortOrder,
				attendeeGroupIDs
			);
			return {
				...state,
				podcasts: sortedUpdatedPods,
				podcast,
				playing: {
					...state.playing,
					podcast: state.playing.podcast.id === podcast.id ? podcast : state.playing.podcast,
				},
			};
		case ADD_NEW_PODCAST:
			return { ...state, podcasts: [...state.podcasts, podcast] };
		case DELETE_PODCAST:
			if (state.podcast && state.podcast.id === podcastID) state.podcast = {};
			const podcastsAfterDelete = deletePodcast(state.podcasts, podcastID);
			return { ...state, podcasts: podcastsAfterDelete };
		case CLEAR_PLAYING_PODCAST:
			return { ...state, playing: initialState.playing };
		case SET_PINNED_PODCAST:
			const pinned = findPinnedPodcast(state.podcasts, state.sortOrder);
			return { ...state, pinned };
		case SET_SORT_ORDER:
			const sortedOrderPods = sortPodcasts(state.podcasts, sortOrder, attendeeGroupIDs);
			return { ...state, podcasts: sortedOrderPods, sortOrder };
		case UPDATE_PLAYED_PODCAST_EPISODES:
			const updatedPlayedPods = updatePodcasts(state.podcasts, podcast);
			return { ...state, podcasts: updatedPlayedPods, podcast };
		case UPDATE_PODCASTS_ROUTE_AS_VIEWED:
			return { ...state, podcastsRouteViewed: viewed };
		case UPDATE_SIDE_PLAYER_VIEW:
			return { ...state, sidePlayerView };
		default:
			return state;
	}
};

export default podcastsReducer;
